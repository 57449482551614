// Copyright ID Business Solutions Ltd. 2023

import { useEffect } from 'react';
import { SpreadsheetMessage } from '../utils/SpreadsheetUtils/SpreadsheetUtils';
import { ActionType, useApplicationState } from '../state/state';
import { useQueryClient } from 'react-query';

const useSpreadsheetPlugin = (): void => {
  const { dispatch } = useApplicationState();
  const queryClient = useQueryClient();

  useEffect(() => {
    const listenToSSEvents = (e: MessageEvent) => {
      const type: SpreadsheetMessage = e.data.type;

      switch (type) {
        case SpreadsheetMessage.SHOW:
          dispatch({ type: ActionType.RESET });
          // We need to remove the stale queries to force a refetch of the selected cell data.
          // Without this, the cell data would not be refetched if the same cell is still
          // selected but the value in it has changed. This is because the cell "location" would still be
          // the same so react-query thinks nothing has changed so would not refetch
          queryClient.removeQueries({ stale: true });

          window.parent.postMessage({ type: SpreadsheetMessage.READY }, '*');

          break;

        default:
          break;
      }
    };

    window.addEventListener('message', listenToSSEvents);

    return () => {
      window.removeEventListener('message', listenToSSEvents);
    };
  }, [dispatch, queryClient]);

  useEffect(() => {
    window.parent.postMessage({ type: SpreadsheetMessage.INIT }, '*');
    // ensure we find out the current state when opened or re-opened
    //window.parent.postMessage({ type: SpreadsheetMessage.CURRENT_CELL }, '*');
  }, []);
};

export { useSpreadsheetPlugin };
