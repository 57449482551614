// Copyright ID Business Solutions Ltd. 2024

import React from 'react';
import { ConfirmDialog } from '@idbs/idbs-react-components';
import {
  DialogBody,
  DialogIcon,
  DialogSubHeader,
  DialogText,
  DialogTextContainer,
  DialogTitle,
} from './ConfirmActionDialog.style';
import { AttentionIcon } from '@idbs/idbs-react-icon';

type ConfirmActionDialogParameters = {
  title: string;
  header: string;
  subHeader?: string;
  text: string;
  closeText: string;
  submitText: string;
  onConfirm: () => void;
  onCancel: () => void;
  testId: string;
};

const ConfirmActionDialog = ({
  title,
  header,
  subHeader,
  text,
  closeText,
  submitText,
  onConfirm,
  onCancel,
  testId,
}: ConfirmActionDialogParameters) => {
  const Body = () => {
    return (
      <DialogBody>
        <DialogIcon>
          <AttentionIcon width={30} />
        </DialogIcon>
        <DialogTextContainer>
          <DialogTitle>{header}</DialogTitle>
          <DialogSubHeader>{subHeader}</DialogSubHeader>
          <DialogText>{text}</DialogText>
        </DialogTextContainer>
      </DialogBody>
    );
  };

  return (
    <ConfirmDialog
      body={<Body />}
      onClose={onCancel}
      onSubmit={onConfirm}
      icon={null}
      title={title}
      closeBtnText={closeText}
      submitBtnText={submitText}
      submitBtnPosition='right'
      testId={testId}
      visible
    />
  );
};

export default ConfirmActionDialog;
