// Copyright ID Business Solutions Ltd. 2024
import styled from 'styled-components';
import { Button } from '@idbs/idbs-react-components';

export const DialogBodyTitle = styled.h3`
  padding: 0;
  margin: 0 0 6px;
  color: var(--theme-main-800);
  font-weight: 500;
`;

export const DialogBodyMessage = styled.div`
  margin: 0;
  color: var(--color-neutral-black);
  font-weight: 400;
  line-height: 1.25rem;
`;

export const StyledButton = styled(Button)`
  margin: 0 0 0 5px !important;
`;

export const Flexbox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: normal;

  svg.preset-icon {
    min-width: 30px;
    min-height: 30px;
  }

  & > *:not(:last-child) {
    margin-right: 0.75rem;
  }
`;
