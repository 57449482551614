// Copyright ID Business Solutions Ltd. 2023

import { Tree } from '@idbs/idbs-react-components';
import { ActionType, useApplicationState } from '../../../state/state';
import React from 'react';
import { NamedItem } from '../../../types/shared';
import { NamedNode, DisplayTree } from './DisplayTree';

type TreeProps = {
  treeData: DisplayTree;
  closeDropdown: () => void;
};

type Path = {
  itemsHierarchy: Array<NamedItem>;
};

const SiteProductTree = ({ treeData, closeDropdown }: TreeProps) => {
  const { dispatch } = useApplicationState();
  // mark all sites as auto expand:
  const autoExpandedRootNodes: Array<number> = [];
  treeData.forEach((element, index) => {
    autoExpandedRootNodes[index] = index;
  });

  function isLeaf(item: NamedNode) {
    return !item.children;
  }

  function getNestedItems(item: NamedNode) {
    return item.children;
  }

  const setOfTreeProperties = {
    items: treeData,
    autoExpandedRootNodes,
    getItemTitle: (item: NamedNode) => item.name,
    isLeaf,
    getNestedItems,
    title: '',
  };

  function onLeafClick(path: Path) {
    const items = path.itemsHierarchy;
    const lastItem = items[items.length - 1];
    const product = {
      site: items[0].name,
      siteId: items[0].id,
      product: lastItem.name,
      productId: lastItem.id,
    };
    dispatch({
      type: ActionType.PRODUCT_SELECTED,
      product,
    });
    closeDropdown();
  }

  return <Tree {...setOfTreeProperties} onLeafClick={onLeafClick} testId='product-select-tree' />;
};

export { SiteProductTree };
