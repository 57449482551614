// Copyright ID Business Solutions Ltd. 2024

import { toast } from '@idbs/idbs-react-components';
import { useIntlMessage } from '@idbs/idbs-react-hooks';
import { useMutation } from 'react-query';
import { RestApi } from '../services/RestApi';
import { ActionType, useApplicationState } from '../state/state';
import { ClearDataMutation, Response } from '../types/shared';
import { ImportResponse, SpreadsheetResponse } from '../types/spreadsheet';

export enum ClearDataErrors {
  // SS API rejection
  SS_API_ERROR,
  // network failure or any other reason
  GENERIC,
}

/**
 * A hook that returns a mutation object that calls the spreadsheet API for clearing data to the spreadsheet.
 *
 * If the call succeeds, a toaster is displayed in the app.
 * @returns A react-query mutation object, as well as the error state and setter
 */
export const useClearData = () => {
  const { dispatch } = useApplicationState();
  const intlMessage = useIntlMessage();

  const mutation: ClearDataMutation = useMutation(
    () => {
      return RestApi.clearParameterInformation();
    },
    {
      onSuccess: (importResponse: Response, variables) => {
        if (importResponse) {
          const response = (importResponse as SpreadsheetResponse<ImportResponse>)['batch-response'];
          if (response && typeof response === 'object' && response.status && response.status.success) {
            toast.success(intlMessage('app.clear.data.success.toaster'), null, { autoClose: 2500 });
          } else {
            dispatch({
              type: ActionType.SET_CLEAR_DATA_ERROR,
              error: ClearDataErrors.SS_API_ERROR,
            });
          }
        }
      },
      onError: () => {
        dispatch({
          type: ActionType.SET_CLEAR_DATA_ERROR,
          error: ClearDataErrors.GENERIC,
        });
      },
    }
  );

  return { mutation };
};

export default useClearData;
