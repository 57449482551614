// Copyright ID Business Solutions Ltd. 2023

import styled from 'styled-components';

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  button {
    box-sizing: border-box;
  }

  & div {
    gap: 0;
  }
`;
