// Copyright ID Business Solutions Ltd. 2023

import React from 'react';
import { useIntlMessage } from '@idbs/idbs-react-hooks';
import { ActionType, useApplicationState } from '../../../state/state';
import { actionImportData } from '../../ImportData/ImportData';
import ConfirmActionDialog from '../ConfirmActionDialog/ConfirmActionDialog';
import { ImportMutation } from '../../../types/shared';

type ConfirmImportDialogParamaters = {
  mutation: ImportMutation;
};

const ConfirmImportDialog = ({ mutation }: ConfirmImportDialogParamaters) => {
  const { state } = useApplicationState();
  const intlMessage = useIntlMessage();
  const { dispatch } = useApplicationState();

  const title = intlMessage('import.parameters');
  const header = intlMessage('confirm.import.dialog.header');
  const subHeader = intlMessage('confirm.import.dialog.sub.header');
  const text = intlMessage('confirm.import.overwrite');
  const closeText = intlMessage('app.import.failed.cancel');
  const submitText = intlMessage('app.import.failed.submit');
  const testId = 'confirm-import-dialog';

  const onConfirm = () => {
    dispatch({ type: ActionType.HIDE_CONFIRM_IMPORT_DIALOG });
    actionImportData(mutation, state.confirmImportDialogDetails?.steps);
  };

  const onCancel = () => {
    dispatch({ type: ActionType.HIDE_CONFIRM_IMPORT_DIALOG });
    dispatch({ type: ActionType.RESET });
  };

  return (
    <ConfirmActionDialog
      onCancel={onCancel}
      onConfirm={onConfirm}
      title={title}
      header={header}
      subHeader={subHeader}
      text={text}
      closeText={closeText}
      submitText={submitText}
      testId={testId}
    />
  );
};

export default ConfirmImportDialog;
