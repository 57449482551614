// Copyright ID Business Solutions Ltd. 2023

import styled from 'styled-components';

export const footerHeight = '50px';

export const FooterContainer = styled.div`
  min-height: ${footerHeight};
  background-color: var(--color-neutral-100);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
`;

export const AckLink = styled.a`
  color: var(--color-neutral-700);
  font-size: 14px;
`;

export const BuildNumber = styled.p`
  color: var(--color-neutral-700);
  font-size: 14px;
`;

export const EmptyRight = styled.p`
  color: var(--color-neutral-700);
  font-size: 14px;
  color: var(--color-neutral-100);
  visibility: hidden;
`;
