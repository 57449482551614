// Copyright ID Business Solutions Ltd. 2023

import React from 'react';
import { ActionPanel, ActionPanelEndContent } from '@idbs/idbs-react-components';

import { useApplicationState } from '../../state/state';
import { ImportData } from '../ImportData/ImportData';
import { ProductSelect } from '../ProductSelect/ProductSelect';

import { HeaderContainer } from './Header.style';
import { ImportMutation, ClearDataMutation } from '../../types/shared';
import { ClearData } from '../ClearData/ClearData';

type HeaderProps = {
  mutation: ImportMutation;
  clearDataMutation: ClearDataMutation;
};

const Header = ({ mutation, clearDataMutation }: HeaderProps) => {
  const {
    state: { selectedSteps },
  } = useApplicationState();

  return (
    <HeaderContainer>
      <ActionPanel testId='application-header'>
        {selectedSteps && selectedSteps.length > 0 ? <ImportData mutation={mutation} /> : <ProductSelect />}
        <ActionPanelEndContent>
          <ClearData mutation={clearDataMutation} />
        </ActionPanelEndContent>
      </ActionPanel>
    </HeaderContainer>
  );
};

export { Header };
