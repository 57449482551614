// Copyright ID Business Solutions Ltd. 2023

import React from 'react';
import ReactDOM from 'react-dom/client';

import '@idbs/idbs-analytics-app/idbs-analytics-app.js';
import { ApplicationErrorBoundary } from '@idbs/idbs-react-components';
import { IntlProvider } from '@idbs/idbs-react-hooks';
import '@idbs/idbs-themed-app/idbs-themed-app.js';
import 'regenerator-runtime/runtime';
// Pull in your applications default style - this will be applied on startup and if the config service is configured
// and a theme is specified for the tenant, then the style specified by the theme will be used to override this default
import IdbsAuth from '@idbs/idbs-auth';
import '@idbs/idbs-themes/styles/polar/style.css';

import { App } from './App';
import './index.css';
import { INITIAL_STATE, StateProvider } from './state/state';
import translations from './translations';
import { ConfigService } from './utils/ConfigService/ConfigService';
import { QueryClientProvider, QueryClient } from 'react-query';

import { mockAuth } from './__mocks__/mockAuth/mockAuth';

interface IdbsAnalyticsAppProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
  analyticsid: string;
  configurl: string;
}

interface IdbsThemedAppProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
  configurl: string;
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'idbs-analytics-app': IdbsAnalyticsAppProps;
      'idbs-themed-app': IdbsThemedAppProps;
    }
  }
}

/*
 * See https://github.com/IDBusinessSolutions/idbs-analytics-app for instructions on how to
 * setup google analytics for your application.
 * Also see https://idbs-hub.atlassian.net/wiki/spaces/WEBDEV/pages/3520823396/Google+Tag+Manager+Application+Analytics
 * for further instructions and information that will be helpful
 */
const analyticsId: string = 'GTM-WFSZBXL';

// Wait for our mock service worker to start before doing anything else, note this
// only has any effect in 'dev' mode, in 'prod' mode the promise will resolve instantly
const prepareMockServer = async () => {
  if (process.env.NODE_ENV === 'development') {
    const { worker } = await import('./__mocks__/browser');
    return worker.start();
  }

  return Promise.resolve();
};

const url = window.location.hostname;
const tenant = url.split('.').shift()!;

const initialState = {
  ...INITIAL_STATE,
  tenant,
};

const auth: any = new IdbsAuth({
  tenantName: import.meta.env.VITE_TENANT ?? tenant,
  realm: 'eln',
  scopes: 'eln,pims',
});

const isNoMock = import.meta.env.PROD || import.meta.env.VITE_NO_MOCK === 'true';

const configServiceUrl = isNoMock ? ConfigService.formConfigServiceUrl(url) : 'https://config-service.com';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      retry: 0,
      refetchOnWindowFocus: false,
    },
  },
});

// Render mock application
const renderMockApplication = () => {
  return renderApplication(mockAuth);
};

// Render the application
const renderApplication = (authToUse: any) => {
  ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <idbs-analytics-app analyticsid={analyticsId} configurl={configServiceUrl} />
      <idbs-themed-app configurl={configServiceUrl}>
        <ApplicationErrorBoundary>
          <IntlProvider translations={translations}>
            <StateProvider initialState={initialState}>
              <QueryClientProvider client={queryClient}>
                <App configServiceUrl={configServiceUrl} auth={authToUse} />
              </QueryClientProvider>
            </StateProvider>
          </IntlProvider>
        </ApplicationErrorBoundary>
        {/* The following will ensure that the app isn't rendered until AFTER any themeing
            has been applied by the idbs-themed-app web component. Doing this prevents a
            'flash' of style change if the apps default styling doesn't match what the
            config service returns (i.e. app is displayed initially as ELN but then switches
            to Polar). This does cause a slight delay in the display of the app as it has to
            wait for a response/failure from the config service (if configured), but this is
            preferable to the style 'flash'. If this is not acceptable, then just remove the
            'placeholder' div below */}
        <div slot='placeholder' />
      </idbs-themed-app>
    </React.StrictMode>
  );
};

// eslint-disable-next-line no-negated-condition
if (isNoMock) {
  // eslint-disable-next-line require-await
  auth.onPageLoad(async (error, isAuthenticated) => {
    if (isAuthenticated) {
      renderApplication(auth);
    } else {
      auth.login(false, window.location.href);
    }
  });
} else {
  prepareMockServer().then(renderMockApplication);
}
