// Copyright ID Business Solutions Ltd. 2023

import React from 'react';
import { Tree, Checkbox } from '@idbs/idbs-react-components';

import { ActionType, useApplicationState } from '../../../state/state';
import { SelectedStep, Step, Unit } from '../../../types/shared';
import { TreeContainer } from './StepTree.style';

type TreeProps = {
  treeData: Array<Unit>;
  onError: () => void;
};

const isNotSelected = (item: Step) => false;

const StepTree = ({ treeData, onError }: TreeProps) => {
  const {
    state: { selectedSteps, selectedProductId, selectedSiteId, selectedProduct, selectedSite },
    dispatch,
  } = useApplicationState();

  const isStepIdSelected = (stepId: number) => selectedSteps && selectedSteps.some((step) => step.id === stepId);
  const isSelected = (item: Step) => isStepIdSelected(item.id);

  const getSteps = (item: Unit): Step[] => {
    return [];
  };

  const isLeaf = (item: Step | Unit) => true;

  const renderCheckbox = (item: Step | Unit) => {
    const onCheckedChanged = (checked: boolean) => {
      let stepsToSelect = selectedSteps ? [...selectedSteps] : [];
      if (checked && !isSelected(item)) {
        const selectedStep: SelectedStep = {
          ...item,
          site_id: selectedSiteId as number,
          site_name: selectedSite as string,
          product_id: selectedProductId as number,
          product_name: selectedProduct as string,
        };
        stepsToSelect.push(selectedStep);
      }
      if (!checked && isSelected(item)) {
        stepsToSelect = stepsToSelect.filter((step) => step.id !== item.id);
      }
      const orderedStepsToSelect: Array<SelectedStep> = [];
      const orderedUnitOpNames = treeData.map((unitOp) => unitOp.name);
      orderedUnitOpNames.forEach((unitOpName) => {
        const foundUnitOp = stepsToSelect.find((step) => step.name === unitOpName);
        if (foundUnitOp) {
          orderedStepsToSelect.push(foundUnitOp);
        }
      });
      dispatch({
        type: ActionType.SELECT_STEPS,
        steps: orderedStepsToSelect,
      });
    };

    return <Checkbox checked={isSelected(item)} onCheckedChanged={onCheckedChanged} />;
  };

  const setOfTreeProperties = {
    items: treeData,
    getItemTitle: (item: Step) => item.name,
    isSelected: isNotSelected,
    renderIcon: renderCheckbox,
    isLeaf,
    getNestedItems: (item: Unit) => getSteps(item),
    title: '',
  };

  return (
    <TreeContainer>
      <Tree {...setOfTreeProperties} testId='step-tree' />
    </TreeContainer>
  );
};
export { StepTree };
