// Copyright ID Business Solutions Ltd. 2023

enum ENVS {
  QA = 'qa',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

enum HOSTED_ZONE {
  DEV = 'idbs-dev.com',
  STAGING = 'idbs-staging.com',
  CLOUD = 'idbs-cloud.com',
}

type Module = {
  url: string;
  description?: string;
};
type Modules = {
  eln?: Module;
  process?: Module;
};

/*
qa is on idbs-cloud
staging is on idbs-dev
production is idbs-cloud
*/

class ConfigService {
  static #modules: Modules = {};
  static #configServiceUrl: string = 'https://config-service.com';

  /**
   * Forms the config service URL by getting the tenant, environment,
   * and hosted zone configuration from the current URL
   */
  static formConfigServiceUrl(url: string): string {
    if (import.meta.env.VITE_CONFIG_SERVICE_URL) {
      return import.meta.env.VITE_CONFIG_SERVICE_URL;
    }

    const urlParts = url.split('.');

    let tenant = urlParts[0];
    const zone = urlParts.slice(2).join('.');

    // can override tenant with env var
    if (import.meta.env.VITE_TENANT) {
      tenant = import.meta.env.VITE_TENANT;
    }

    let env: ENVS = ENVS.QA;

    if (zone.includes(HOSTED_ZONE.DEV)) {
      env = ENVS.QA;
    } else if (zone.includes(HOSTED_ZONE.STAGING)) {
      env = ENVS.STAGING;
    } else if (zone.includes(HOSTED_ZONE.CLOUD)) {
      env = ENVS.PRODUCTION;
    }
    this.#configServiceUrl = `https://${tenant}.config-service-${env}.${zone}/v1/query`;
    return this.#configServiceUrl;
  }

  static get eln() {
    if (import.meta.env.DEV && import.meta.env.VITE_ELN) {
      return import.meta.env.VITE_ELN;
    }
    return this.#modules.eln?.url;
  }

  static get configServiceUrl() {
    return this.#configServiceUrl;
  }

  static get process() {
    if (import.meta.env.DEV && import.meta.env.VITE_PIMS) {
      return import.meta.env.VITE_PIMS;
    }
    return this.#modules.process?.url;
  }

  static get modules() {
    const modules = {
      eln: this.eln,
      process: this.process,
    };
    return modules;
  }

  static set modules({ eln, process }) {
    if (eln) {
      this.#modules.eln = { url: eln };
    }
    if (process) {
      this.#modules.process = { url: process };
    }
  }
}

export { ConfigService };
