// Copyright ID Business Solutions Ltd. 2023

import styled from 'styled-components';

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  color: var(--color-neutral-700);
  font-weight: 400;
`;
