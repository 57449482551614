// Copyright ID Business Solutions Ltd. 2023

import { useIntlMessage } from '@idbs/idbs-react-hooks';
import { ConfirmDialog } from '@idbs/idbs-react-components';
import React from 'react';
import { SpreadsheetMessage } from '../../utils/SpreadsheetUtils/SpreadsheetUtils';

type WarningDialogProps = {
  onRetry: () => void;
  header: string;
  title?: string;
};

const onCancel = () => {
  window.parent.postMessage({ type: SpreadsheetMessage.DESTROY }, '*');
};

const WarningDialog = ({ onRetry, header, title }: WarningDialogProps) => {
  const intlMessage = useIntlMessage();
  const dialogTitle = title || intlMessage('app.no.data.title');
  const body = intlMessage('app.no.data.body');
  const closeText = intlMessage('app.no.data.cancel');
  const submitText = intlMessage('app.no.data.retry');
  return (
    <ConfirmDialog
      body={body}
      header={header}
      onClose={onCancel}
      onSubmit={onRetry}
      icon='attention'
      title={dialogTitle}
      closeBtnText={closeText}
      submitBtnText={submitText}
      submitBtnPosition='right'
      testId='warning-dialog'
      visible
    />
  );
};

export { WarningDialog };
