// Copyright ID Business Solutions Ltd. 2024

import { useQuery } from 'react-query';
import { RestApi } from '../../services/RestApi';
import { ActionType, useApplicationState } from '../../state/state';
import { ImportErrors } from '../useImport';
import { ConfigService } from '../../utils/ConfigService/ConfigService';
// import { allBackingTableQueeeries } from '../../utils/SpreadsheetUtils/SpreadsheetUtils';

/**
 * Sends a request to the Spreadsheet API to retrieve the text values of the pims_import_map:
 * table and data_category_range
 *
 * @returns An object containing the table and range text values, the loading state of the network call
 */
const useSpreadsheetMappingInfo = (): {
  isLoadingMappingInfo: boolean;
  table: string;
  range: string;
} => {
  // pims_import_table details:
  const queries = [{ table: 'pims_import_map', range: '' }]; // just the mapping table!

  const { dispatch } = useApplicationState();

  const { isLoading, isError, data } = useQuery({
    queryKey: ['pims_import_map', ''],
    queryFn: () => RestApi.getCells(queries),
    cacheTime: 0, // do not cache as it will lead to outdated cell values
    staleTime: 0,
    enabled: !!ConfigService.eln,
    onError: () => {
      dispatch({ type: ActionType.SET_IMPORT_ERROR, error: ImportErrors.GENERIC });
    },
    onSuccess: (response) => {
      if (!response?.['batch-response'].status.success) {
        // if error was "not found" we will ignore it but return no data
        if (response?.['batch-response'].status.statusCode !== 404) {
          dispatch({ type: ActionType.SET_IMPORT_ERROR, error: ImportErrors.GENERIC });
        }
      }
    },
  });

  if (!data?.['batch-response'].status.success || isError) {
    return { isLoadingMappingInfo: false, table: '', range: '' };
  }

  const cellValues = data?.['batch-response']['api-responses'][0].tables[0].ranges[0].data;
  const table = cellValues.map((cell) => cell['table:pims_import_map'])[0].string?.trim() || '';
  const range = cellValues.map((cell) => cell['data_category_range:pims_import_map'])[0].string?.trim() || '';

  return {
    isLoadingMappingInfo: isLoading,
    table,
    range,
  };
};

export default useSpreadsheetMappingInfo;
