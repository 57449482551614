// Copyright ID Business Solutions Ltd. 2023

import { useIntlMessage } from '@idbs/idbs-react-hooks';
import React from 'react';
import imgUrl from '../../../assets/blank.png';
import { ContentWarningMessage } from '../Content.style';

const ChooseProductMessage = () => {
  const intlMessage = useIntlMessage();

  const message = intlMessage('app.select');

  return (
    <>
      <img data-testid='nothing-selected-image' alt={message} src={imgUrl} width={125} />
      <ContentWarningMessage data-testid='no-products-message'> {message} </ContentWarningMessage>
    </>
  );
};

export default ChooseProductMessage;
