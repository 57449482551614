// Copyright ID Business Solutions Ltd. 2023

import { useQuery } from 'react-query';
import { RestApi } from '../services/RestApi';
import { ActionType, useApplicationState } from '../state/state';
import { FoundStep, ImportMutation } from '../types/shared';
import { ImportErrors } from './useImport';
import useSpreadsheetCellData from './useSpreadsheetCellData/useSpreadsheetCellData';
import { ConfigService } from '../utils/ConfigService/ConfigService';
import { actionImportData } from '../components/ImportData/ImportData';
import useSpreadsheetMappingInfo from './useSpreadsheetMappingInfo/useSpreadsheetMappingInfo';

type AutomaticImportReturn = {
  isRetrievingAutomaticImport: boolean;
  isImportingAutomaticImport: boolean;
  mutation: ImportMutation;
  cellValues: undefined | Array<string>;
};

/**
 * If the fist cell in pims_import_map table isn't empty when the panel is opened,
 * an automatic import is triggered
 */
const useAutomaticImport = (mutation: ImportMutation): AutomaticImportReturn => {
  const { dispatch } = useApplicationState();

  // Retrieve all the information about the spreadsheet tables
  const { isLoadingMappingInfo, table, range } = useSpreadsheetMappingInfo();

  // Retrieve all the information about the spreadsheet tables
  const { isLoadingCellData, cellValues, isTablesEmpty } = useSpreadsheetCellData(table, range);
  const validCellValues = cellValues && cellValues.filter((value) => value && value.length > 0);

  const { isLoading: isLoadingStepIds } = useQuery({
    queryKey: ['find-step', validCellValues],
    queryFn: () => RestApi.findMultipleSteps(validCellValues),
    enabled: !!validCellValues && validCellValues.length > 0 && !!ConfigService.eln,
    cacheTime: 0, // ensure we refetch for the most up-to-date data
    onSuccess(steps) {
      const passedValues: Array<FoundStep> = [];
      validCellValues.forEach((stepName) => {
        const searchResult = steps?.filter(
          (step) => step.level === 1 && stepName.localeCompare(step.name, undefined, { sensitivity: 'base' }) === 0
        );
        if (searchResult.length === 0) {
          dispatch({ type: ActionType.SET_IMPORT_ERROR, error: ImportErrors.NOT_FOUND, stepName });
        } else if (searchResult?.length > 1) {
          dispatch({ type: ActionType.SET_IMPORT_ERROR, error: ImportErrors.MULTIPLE_STEPS_FOUND, stepName });
        } else {
          passedValues.push(searchResult[0]);
        }
      });
      if (validCellValues.length === passedValues.length) {
        // only allow an auto import if there no errors, i.e. all found one and onley one match
        dispatch({ type: ActionType.SELECT_STEPS, steps: passedValues });

        // If there are data already in the background tables, we will ask the user to confirm before the auto-import
        if (isTablesEmpty) {
          actionImportData(mutation, passedValues);
        } else {
          dispatch({ type: ActionType.SHOW_CONFIRM_IMPORT_DIALOG, steps: passedValues });
        }
      }
    },
    onError: () => {
      dispatch({
        type: ActionType.SET_IMPORT_ERROR,
        error: ImportErrors.NOT_FOUND,
      });
    },
  });

  return {
    isRetrievingAutomaticImport: isLoadingStepIds || isLoadingCellData || isLoadingMappingInfo,
    isImportingAutomaticImport: mutation.isLoading,
    mutation,
    cellValues: validCellValues,
  };
};

export { useAutomaticImport };
