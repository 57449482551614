// Copyright ID Business Solutions Ltd. 2023

import styled from 'styled-components';

export const Shell = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
`;
