// Copyright ID Business Solutions Ltd. 2024

import { ParameterData } from './ParameterUtils';

export type MultiSpecLimitData = Array<MultiSpecLimit>;

type MultiSpecLimit = {
  id: number;
  parameter_id: number;
  table: string;
  parameter: string;
  unit_of_measure: string;
  query_alias: string;
  parameter_class: string;
  sub_product: string;
  market: string;
  status: string;
  valid_from: string;
  valid_through: string;
  box_cox_lambda: string;
  process_target: string;
  limit_ranges: Array<LimitRange>;
  acceptable_values: Array<string>;
  acceptance_criteria: string;
  method: string;
  comments: string;
  change_reason: string;
  unit_operation: string;
  phase: string;
  sub_phase: string;
};

type LimitRange = {
  specification_limit_name: string;
  lower_limit_type: string;
  lower_limit_value: string;
  upper_limit_type: string;
  upper_limit_value: string;
};

const emptyLimitRange: LimitRange = {
  specification_limit_name: '',
  lower_limit_type: '',
  lower_limit_value: '',
  upper_limit_type: '',
  upper_limit_value: '',
};

/**
 * Convert the array of spec limits (potentially containing array of limit ranges)
 * returned by getMultiSpecLimits into an array of parameter data
 * by mapping each of those limit ranges into a separate array element
 */
export const specLimitToParameterData = (specLimitData: MultiSpecLimitData): Array<ParameterData> => {
  return specLimitData.flatMap((attributes) => {
    if (!attributes.limit_ranges || attributes.limit_ranges.length === 0) {
      attributes.limit_ranges = [emptyLimitRange];
    }
    return attributes.limit_ranges.map((limit_range) => {
      const attributePerLimitRange = { ...attributes, limit_ranges: limit_range };
      return attributePerLimitRange;
    });
  });
};
