// Copyright ID Business Solutions Ltd. 2023

/* eslint-disable max-statements */
/* eslint-disable no-nested-ternary */
/// <reference types="vite/client" />
import { Dots, ErrorPage, LoadingIndicator, Toaster } from '@idbs/idbs-react-components';
import { useConfigService, useIntlMessage } from '@idbs/idbs-react-hooks';
import React from 'react';
import { useQuery } from 'react-query';

import { useSpreadsheetPlugin } from './hooks/useSpreadsheetPlugin';

import { Content } from './components/Content/Content';
import { Header } from './components/Header/Header';
import { WarningDialog } from './components/WarningDialog/WarningDialog';

import { RestApi, RestApiErrorMessage } from './services/RestApi';
import { Auth } from './utils/Auth/Auth';
import { ConfigService } from './utils/ConfigService/ConfigService';

import { Shell } from './App.style';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import Footer from './components/Footer/Footer';
import { useAutomaticImport } from './hooks/useAutomaticImport';
import { useImport } from './hooks/useImport';
import { useClearData } from './hooks/useClearData';
import { VerifyDialog } from './components/VerifyDialog/VerifyDialog';
import ConfirmDialogs from './components/ConfirmDialogs/ConfirmDialogs';

export const queryKeySites = ['sites'];
export const queryKeyUserInfo = ['userInfo'];

type AppProps = {
  configServiceUrl: string;
  auth: any;
};

const App = ({ configServiceUrl, auth }: AppProps) => {
  useSpreadsheetPlugin();
  const intlMessage = useIntlMessage();
  const header = intlMessage('app.no.product.header');

  const {
    modules: { eln, process },
    isLoading: isLoadingConfigService,
    isSuccessful: isSuccessfulConfigService,
    isError: isErrorConfigService,
  } = useConfigService(configServiceUrl, Auth.getToken());

  const {
    isLoading: isLoadingSites,
    isError: isErrorSites,
    isSuccess: isSuccessSites,
    error: errorSites,
    refetch,
  } = useQuery({
    queryKey: queryKeySites,
    queryFn: () => RestApi.getSites(),
    enabled: !!process,
  });

  type UserInfo = {
    name: string;
    given_name: string;
    family_name: string;
    'http://ecs/platform/x_idbs_user': string;
  };

  const { isLoading: isLoadingUserInfo } = useQuery({
    queryKey: queryKeyUserInfo,
    queryFn: async () => {
      const userInfo = (await auth.getUserInfo()) as UserInfo;
      const user = `${userInfo['http://ecs/platform/x_idbs_user']} (${userInfo.given_name} ${userInfo.family_name})`;
      return user;
    },
  });

  const { mutation: clearDataMutation } = useClearData();

  const { mutation: importMutation } = useImport();

  const { isRetrievingAutomaticImport, isImportingAutomaticImport } = useAutomaticImport(importMutation);

  const onRetry = () => {
    refetch({ throwOnError: true });
  };

  const isLoading = isLoadingConfigService || isLoadingSites || isLoadingUserInfo;

  if (isErrorConfigService || (isSuccessfulConfigService && (!eln || !process))) {
    return <ErrorPage />;
  } else if (isSuccessfulConfigService) {
    ConfigService.modules = { eln, process };
  }

  if (isErrorSites) {
    if (errorSites instanceof Error && errorSites.message === RestApiErrorMessage.NOT_VERIFIED) {
      return <VerifyDialog />;
    }
    return <WarningDialog onRetry={onRetry} header={header} />;
  }

  return (
    <Shell data-testid='application-shell' id='app-root'>
      <Toaster />
      {!isLoading && <Header mutation={importMutation} clearDataMutation={clearDataMutation} />}
      <LoadingIndicator
        visible={isRetrievingAutomaticImport || isImportingAutomaticImport}
        text={isImportingAutomaticImport ? intlMessage('importing.data') : intlMessage('retrieving.data')}
        testId='automatic-import-loading'
      />
      {isLoading ? <Dots /> : isSuccessSites ? <Content /> : ''}
      <Footer />
      <ErrorDialog mutation={importMutation} />
      <ConfirmDialogs importMutation={importMutation} clearDataMutation={clearDataMutation} />
    </Shell>
  );
};

export { App };
