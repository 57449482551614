// Copyright ID Business Solutions Ltd. 2023

import { NamedItem, Site } from '../../../types/shared';

interface NamedNode extends NamedItem {
  children: Array<NamedNode | NamedItem>;
}

type DisplayTree = Array<NamedNode | NamedItem>;

const makeChildren = (items: Array<NamedItem>): Array<NamedNode | NamedItem> => {
  return items.map((item) => ({
    id: item.id,
    name: item.name,
  }));
};

const SitesToDisplayTree = (sites: Array<Site>) => {
  /* For each site in sites:
      create a node from it
      if it contains process_tempates:
          convert the process_templates into the products of a sub-node: 'Site Templates'
          add sub-node into the node's children
    */
  const tree: DisplayTree = sites.map((site) => {
    const node: NamedNode = {
      id: site.id,
      name: site.name,
      children: makeChildren(site.products),
    };
    if (site.process_templates.length > 0) {
      const processTemplates: NamedNode = {
        id: -1,
        name: 'Process Templates',
        children: makeChildren(site.process_templates),
      };
      node.children.push(processTemplates);
    }
    return node;
  });
  return tree;
};

export { SitesToDisplayTree };
export type { DisplayTree, NamedNode };
