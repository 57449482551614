// Copyright ID Business Solutions Ltd. 2023

import React from 'react';
import { AttentionIcon } from '@idbs/idbs-react-icon';
import { ContentWarningMessage } from '../Content.style';
import { useIntlMessage } from '@idbs/idbs-react-hooks';

const NoProducts = () => {
  const intlMessage = useIntlMessage();

  return (
    <>
      <AttentionIcon data-testid='warning-image' color='var(--color-neutral-200)' width='125px' height='125px' />
      <ContentWarningMessage data-testid='no-products-message'> {intlMessage('app.no.product')} </ContentWarningMessage>
    </>
  );
};

export default NoProducts;
