// Copyright ID Business Solutions Ltd. 2023

import styled from 'styled-components';

export const GenericErrorContainer = styled.div`
  border: 3px solid white;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: start;
`;

export const GenericErrorIconContainer = styled.div`
  border: 20px solid white;
  display: flex;
  flex-direction: column;
  justify-items: first;
  align-items: start;
`;

export const GenericErrorTextContainer = styled.div`
  border: 3px solid white;
  display: flex;
  flex-direction: column;
  align-items: start;
`;
