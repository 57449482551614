// Copyright ID Business Solutions Ltd. 2023

import styled from 'styled-components';

export const ProductSelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
`;

export const SelectedStepWrapper = styled.div`
  margin-left: 10px;
  margin-right: 10px;
`;
