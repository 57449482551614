// Copyright ID Business Solutions Ltd. 2024

import { useIntlMessage } from '@idbs/idbs-react-hooks';
import { ConfirmDialog } from '@idbs/idbs-react-components';
import React from 'react';

type ClearDataErrorDialogProperties = {
  onClose: () => void;
};

const ClearDataErrorDialog = ({ onClose }: ClearDataErrorDialogProperties) => {
  const intlMessage = useIntlMessage();
  const title = intlMessage('app.clear.failed');
  const header = intlMessage('app.clear.failed.reason');
  const body = intlMessage('app.clear.failed.response');
  const closeText = intlMessage('app.clear.failed.cancel');
  return (
    <ConfirmDialog
      body={body}
      header={header}
      onClose={onClose}
      icon='attention'
      title={title}
      closeBtnText={closeText}
      submitBtnText=''
      submitBtnPosition='right'
      visible={true}
      testId='clear-data-error-dialog'
    />
  );
};

export { ClearDataErrorDialog };
