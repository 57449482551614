// Copyright ID Business Solutions Ltd. 2023

import React from 'react';
import { useQueryClient } from 'react-query';
import { queryKeySites } from '../../App';
import { useApplicationState } from '../../state/state';
import { Site } from '../../types/shared';
import { StepSelect } from '../StepSelect/StepSelect';

import { ImportErrors } from '../../hooks/useImport';
import ChooseProductMessage from './ChooseProductMessage/ChooseProductMessage';
import { CenteredContent } from './Content.style';
import FullScreenError from './FullScreenError/FullScreenError';
import NoProducts from './NoProducts/NoProducts';

const Content = () => {
  const queryClient = useQueryClient();
  const sites: Array<Site> | undefined = queryClient.getQueryData(queryKeySites);

  const {
    state: { selectedProductId, importError },
  } = useApplicationState();

  if (importError) {
    const importErrors = Object.keys(importError);
    if (
      importErrors.includes(ImportErrors.NOT_FOUND.toString()) ||
      importErrors.includes(ImportErrors.MULTIPLE_STEPS_FOUND.toString())
    ) {
      return <FullScreenError />;
    }
  }

  const isWithoutProducts = sites?.length === 0;

  let content: JSX.Element;
  if (selectedProductId) {
    content = <StepSelect selectedProductId={selectedProductId} />;
  } else if (isWithoutProducts) {
    content = <NoProducts />;
  } else {
    content = <ChooseProductMessage />;
  }

  return <CenteredContent data-testid='application-content'>{content}</CenteredContent>;
};

export { Content };
