// Copyright ID Business Solutions Ltd. 2023

import React, { createContext, useContext, useReducer } from 'react';
import { SelectedStep, StaticData } from '../types/shared';
import { ImportErrors } from '../hooks/useImport';
import { ClearDataErrors } from '../hooks/useClearData';

type ImportErrorTypes = {
  [index: string]: Array<string>;
};

export interface AppState {
  selectedProductId?: number;
  selectedSiteId?: number;
  selectedProduct?: string;
  selectedSite?: string;
  selectedSteps?: Array<SelectedStep>;
  confirmImportDialogDetails?: {
    visible: boolean;
    steps?: Array<SelectedStep>;
    clearData?: boolean;
  };
  importError?: ImportErrorTypes;
  clearDataError?: ClearDataErrors;
}

export enum ActionType {
  PRODUCT_SELECTED,
  SELECT_STEPS,
  RESET,
  SHOW_CONFIRM_IMPORT_DIALOG,
  HIDE_CONFIRM_IMPORT_DIALOG,
  SHOW_CLEAR_DATA_DIALOG,
  HIDE_CLEAR_DATA_DIALOG,
  SET_IMPORT_ERROR,
  CLEAR_IMPORT_ERROR,
  SET_CLEAR_DATA_ERROR,
}

type Action =
  | { type: ActionType.PRODUCT_SELECTED; product: StaticData }
  | { type: ActionType.SELECT_STEPS; steps: Array<SelectedStep> }
  | { type: ActionType.RESET }
  | { type: ActionType.SHOW_CONFIRM_IMPORT_DIALOG; steps: Array<SelectedStep> }
  | { type: ActionType.HIDE_CONFIRM_IMPORT_DIALOG }
  | { type: ActionType.SHOW_CLEAR_DATA_DIALOG }
  | { type: ActionType.HIDE_CLEAR_DATA_DIALOG }
  | { type: ActionType.SET_IMPORT_ERROR; error: ImportErrors; stepName?: string }
  | { type: ActionType.CLEAR_IMPORT_ERROR }
  | { type: ActionType.SET_CLEAR_DATA_ERROR; error: ClearDataErrors };

export interface AppContext {
  state: AppState;
  dispatch: React.Dispatch<Action>;
}

export const INITIAL_STATE: AppState = {};

const StoreContext = createContext<AppContext>({
  state: INITIAL_STATE,
  dispatch: () => {},
});

// Handles the state changes
export const reducer = (state: AppState, action: Action): AppState => {
  const { type } = action;
  switch (type) {
    case ActionType.PRODUCT_SELECTED:
      return {
        ...state,
        selectedProductId: action.product.productId,
        selectedProduct: action.product.product,
        selectedSiteId: action.product.siteId,
        selectedSite: action.product.site,
        importError: undefined,
        clearDataError: undefined,
      };
    case ActionType.SELECT_STEPS: {
      return {
        ...state,
        selectedSteps: action.steps,
      };
    }
    case ActionType.RESET:
      return {
        ...state,
        selectedSteps: undefined,
        selectedProductId: undefined,
        confirmImportDialogDetails: { visible: false },
        importError: undefined,
        clearDataError: undefined,
      };
    case ActionType.SHOW_CONFIRM_IMPORT_DIALOG:
      return {
        ...state,
        confirmImportDialogDetails: { visible: true, steps: action.steps },
      };
    case ActionType.SHOW_CLEAR_DATA_DIALOG:
      return {
        ...state,
        confirmImportDialogDetails: { visible: true, clearData: true },
      };
    case ActionType.HIDE_CONFIRM_IMPORT_DIALOG:
    case ActionType.HIDE_CLEAR_DATA_DIALOG:
      return { ...state, confirmImportDialogDetails: { visible: false } };
    case ActionType.SET_IMPORT_ERROR: {
      const importError = state.importError || {};
      const key = action.error.toString();
      const stepNames = importError[key] || [];
      stepNames.push(action.stepName || '');
      importError[key] = stepNames;
      return { ...state, importError };
    }
    case ActionType.CLEAR_IMPORT_ERROR:
      return { ...state, importError: undefined };
    case ActionType.SET_CLEAR_DATA_ERROR:
      return { ...state, clearDataError: action.error };
  }
};

// Used at the top level of the application to initialise the state handling
export const StateProvider = ({ children, initialState = INITIAL_STATE }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <StoreContext.Provider value={{ state, dispatch }}>{children}</StoreContext.Provider>;
};

// Used by the application to gain access to the state and a mechanism (dispatch) via which state changes can be requested
export const useApplicationState = () => useContext(StoreContext);
