// Copyright ID Business Solutions Ltd. 2023

/* eslint-disable no-else-return */
import { useIntlMessage } from '@idbs/idbs-react-hooks';
import React from 'react';
import { ImportErrors } from '../../hooks/useImport';
import { ActionType, useApplicationState } from '../../state/state';
import { ImportMutation } from '../../types/shared';
import { WarningDialog } from '../WarningDialog/WarningDialog';
import { ImportErrorDialog } from './ImportErrorDialog/ImportErrorDialog';
import { ClearDataErrorDialog } from './ClearDataErrorDialog/ClearDataErrorDialog';
import { ImportWarningDialog } from './ImportWarningDialog/ImportWarningDialog';
import { useQueryClient } from 'react-query';
import { actionImportData } from '../ImportData/ImportData';

type ErrorDialogProps = {
  mutation: ImportMutation;
};

const ErrorDialog = ({ mutation }: ErrorDialogProps) => {
  const intlMessage = useIntlMessage();
  const {
    state: { selectedSteps, importError, clearDataError },
    dispatch,
  } = useApplicationState();
  const queryClient = useQueryClient();

  const onRetryImport = () => {
    queryClient.removeQueries({ stale: true });
    dispatch({ type: ActionType.CLEAR_IMPORT_ERROR });

    if (selectedSteps !== undefined) {
      actionImportData(mutation, selectedSteps);
    }
  };

  const onClose = () => {
    dispatch({ type: ActionType.RESET }); // clear everything on close
  };

  if (importError) {
    const importErrors = Object.keys(importError);
    if (importErrors.includes(ImportErrors.SS_API_ERROR.toString())) {
      return <ImportErrorDialog onClose={onClose} />;
    } else if (importErrors.includes(ImportErrors.NO_PARAMETERS.toString())) {
      return <ImportWarningDialog onClose={onClose} />;
    } else if (
      importErrors.includes(ImportErrors.NO_PARAMETERS.toString()) ||
      importErrors.includes(ImportErrors.GENERIC.toString())
    ) {
      return (
        <WarningDialog
          onRetry={onRetryImport}
          header={intlMessage('app.no.parameter.header')}
          title={intlMessage('app.import.failed')}
        />
      );
    }
  } else if (clearDataError) {
    return <ClearDataErrorDialog onClose={onClose} />;
  }
  return <></>;
};

export default ErrorDialog;
