// Copyright ID Business Solutions Ltd. 2023

import { useQuery } from 'react-query';
import { RestApi } from '../../services/RestApi';
import {
  SpreadsheetUtils,
  allBackingTableQueeeries,
  LIMITS_TABLE_NAME,
  PROCESS_TABLE_NAME,
} from '../../utils/SpreadsheetUtils/SpreadsheetUtils';
import { ActionType, useApplicationState } from '../../state/state';
import { ImportErrors } from '../useImport';
import { ConfigService } from '../../utils/ConfigService/ConfigService';

/**
 * Sends a request to the Spreadsheet API to retrieve the text value of the pims_import_map table,
 * as well as the contents of the pims background tables we'll be importing to.
 *
 * @returns An object containing the text value of the cell, the loading state of the network call,
 * and whether the pims background tables are empty or not
 */
const useSpreadsheetCellData = (
  tableName: string,
  range: string
): {
  isLoadingCellData: boolean;
  cellValues: Array<string>;
  isTablesEmpty?: boolean;
} => {
  const queries = [...allBackingTableQueeeries];
  queries.push({ table: tableName, range: `'${range}'` }); // ensure range is parsable

  const { dispatch } = useApplicationState();

  const { isLoading, isError, data } = useQuery({
    queryKey: [tableName, range],
    queryFn: () => RestApi.getCells(queries),
    cacheTime: 0, // do not cache as it will lead to outdated cell values
    staleTime: 0,
    enabled: !!ConfigService.eln && !!tableName && !!range,
    onError: () => {
      dispatch({ type: ActionType.SET_IMPORT_ERROR, error: ImportErrors.GENERIC });
    },
    onSuccess: (response) => {
      if (!response?.['batch-response'].status.success) {
        // if error was "not found" we will ignore it but return no data
        if (response?.['batch-response'].status.statusCode !== 404) {
          dispatch({ type: ActionType.SET_IMPORT_ERROR, error: ImportErrors.GENERIC });
        }
      }
    },
  });

  if (!data?.['batch-response'].status.success || isError) {
    return { isLoadingCellData: false, cellValues: [] };
  }

  const tables = data['batch-response']['api-responses'][0].tables;
  const parameterTable = tables.find((table) => table.name === PROCESS_TABLE_NAME)?.ranges[0].data;
  const limitsTable = tables.find((table) => table.name === LIMITS_TABLE_NAME)?.ranges[0].data;

  const isParameterTableEmpty = SpreadsheetUtils.isTableEmpty(parameterTable);
  const isLimitsTableEmpty = SpreadsheetUtils.isTableEmpty(limitsTable);

  const cellValues = tables
    .find((table) => table.name === tableName)
    ?.ranges[0].data.map((cell) => cell[range])
    .map((cell) => (cell.string?.trim() || cell.number?.toString()) ?? '');

  return {
    isLoadingCellData: isLoading,
    cellValues: cellValues || [],
    isTablesEmpty: isParameterTableEmpty && isLimitsTableEmpty,
  };
};

export default useSpreadsheetCellData;
