// Copyright ID Business Solutions Ltd. 2023

import { AttentionIcon } from '@idbs/idbs-react-icon';
import React from 'react';
import { GenericErrorContainer, GenericErrorIconContainer, GenericErrorTextContainer } from './GenericError.style';

type GenericErrorProps = {
  iconColour: 'yellow' | 'red';
  message: string | Array<string>;
  explainer?: string;
  children?;
};

const GenericError = ({ iconColour, message, explainer }: GenericErrorProps) => {
  const messages =
    typeof message === 'string' ? (
      message
    ) : (
      <>
        {message.map((text, index) => (
          <p key={index}>{text}</p>
        ))}
      </>
    );
  return (
    <GenericErrorContainer>
      <GenericErrorIconContainer>
        <AttentionIcon color={`var(--color-${iconColour}-600)`} width='24px' height='24px' border='30px' />
      </GenericErrorIconContainer>
      <div>
        <GenericErrorTextContainer data-testid='generic-error-message'>{messages}</GenericErrorTextContainer>
        {explainer && (
          <GenericErrorTextContainer data-testid='generic-error-explainer'>{explainer}</GenericErrorTextContainer>
        )}
      </div>
    </GenericErrorContainer>
  );
};

export { GenericError };
