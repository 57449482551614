// Copyright ID Business Solutions Ltd. 2023

import React from 'react';
import { useIntlMessage } from '@idbs/idbs-react-hooks';
import { Button } from '@idbs/idbs-react-components';

import { ImportButtonWrapper } from './ImportButton.style';

type ImportButtonProps = {
  onClick: () => void;
  disabled?: boolean;
};

const ImportButton = ({ onClick, disabled }: ImportButtonProps) => {
  const intlMessage = useIntlMessage();

  return (
    <ImportButtonWrapper data-testid='import-button'>
      <Button emphasis='primary' onClick={onClick} disabled={disabled}>
        {intlMessage('app.import.button')}
      </Button>
    </ImportButtonWrapper>
  );
};

export { ImportButton };
