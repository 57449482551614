// Copyright ID Business Solutions Ltd. 2023

import { useIntlMessage } from '@idbs/idbs-react-hooks';
import { useMutation, useQueryClient } from 'react-query';
import { RestApi } from '../services/RestApi';
import { ActionType, useApplicationState } from '../state/state';
import { ImportMutation, ImportMutationRequest, Response } from '../types/shared';
import { ImportResponse, SpreadsheetResponse } from '../types/spreadsheet';
import { SpreadsheetMessage, LIMITS_TABLE_NAME, PROCESS_TABLE_NAME } from '../utils/SpreadsheetUtils/SpreadsheetUtils';
import { queryKeyUserInfo } from '../App';
import { getCatalogData } from '../utils/CatalogUtils/CatalogUtilities';
import { ProcessStepData } from '../utils/ParameterUtils';
import dayjs from 'dayjs';

export enum ImportErrors {
  // SS API rejection
  SS_API_ERROR,
  // selected step contains no params to import
  NO_PARAMETERS,
  // searched for step could not be found
  NOT_FOUND,
  // searched for step has multiple results
  MULTIPLE_STEPS_FOUND,
  // network failure or any other reason
  GENERIC,
}

const datetimeFormatForTimeStamp = 'YYYY-MM-DDTHH:mm:ss';

const processDataHasNoParameters = (processData: ProcessStepData) =>
  processData.parameters.length === 0 &&
  (processData.process_steps.length === 0 ||
    processData.process_steps.every((subSteps) => processDataHasNoParameters(subSteps)));

/**
 * A hook that returns a mutation object that calls the spreadsheet API for importing data to the spreadsheet.
 *
 * If the call succeeds, a toaster is displayed in the spreadsheet and the panel is closed.
 * @returns A react-query mutation object, as well as the error state and setter
 */
export const useImport = () => {
  const {
    state: { selectedProductId, selectedSiteId, selectedProduct, selectedSite },
  } = useApplicationState();
  const { dispatch } = useApplicationState();
  const queryClient = useQueryClient();
  const userString = queryClient.getQueryData(queryKeyUserInfo);
  const intlMessage = useIntlMessage();

  const mutation: ImportMutation = useMutation(
    async (mutationRequest: ImportMutationRequest) => {
      const { steps } = mutationRequest;
      const stepIds = steps.map((step) => step.id);
      const stepCount = stepIds.length;
      const [processDataArray, specLimitsData, tableColumns] = await Promise.all([
        RestApi.getParameters(stepIds),
        RestApi.getMultipleSpecLimits(stepIds),
        RestApi.getTableColumns(),
      ]);

      const existingTables = tableColumns?.['batch-response']?.['api-responses']?.[0];

      // Check the process and limits tables exist
      if (!existingTables?.tables?.[PROCESS_TABLE_NAME] || !existingTables?.tables?.[LIMITS_TABLE_NAME]) {
        dispatch({ type: ActionType.SET_IMPORT_ERROR, error: ImportErrors.SS_API_ERROR });
        return null;
      }
      // if we don't get any parameter data flag "No Paramaters"
      if (
        !processDataArray ||
        processDataArray.length === 0 ||
        processDataArray.every((processData) => processDataHasNoParameters(processData))
      ) {
        dispatch({ type: ActionType.SET_IMPORT_ERROR, error: ImportErrors.NO_PARAMETERS });
        return null;
      }
      // now we know we want to import, get the catalog data
      const { tupleGuids, catalogPropertyData, catalogTermData } = await getCatalogData(processDataArray);

      // get "static" data
      const staticValues = {
        site: { string: selectedSite as string },
        siteId: { number: selectedSiteId as number },
        product: { string: selectedProduct as string },
        productId: { number: selectedProductId as number },
        user: { string: userString as string },
        timestampOfImport: { datetime: dayjs().format(datetimeFormatForTimeStamp) },
      };

      return RestApi.importParameterInformation({
        existingTables,
        staticValues,
        stepCount,
        processDataArray,
        specLimitsData,
        selectedSteps: steps,
        catalogPropertyData,
        catalogTermData,
        tupleGuids,
      });
    },
    {
      onSuccess: (importResponse: Response, variables) => {
        const { steps } = variables;
        const isMultipleIds = steps && steps.length > 1;
        const stepCount = isMultipleIds ? steps.length : 1;
        if (importResponse) {
          const response = (importResponse as SpreadsheetResponse<ImportResponse>)['batch-response'];
          if (response && typeof response === 'object' && response.status && response.status.success) {
            const longMessage =
              stepCount > 1
                ? intlMessage('app.import.success.many', { stepCount })
                : intlMessage('app.import.success.one');
            window.parent.postMessage(
              {
                type: SpreadsheetMessage.SUCCESS,
                data: {
                  shortMessage: intlMessage('app.import.success.title'),
                  longMessage,
                  options: {
                    messageType: 'notification',
                  },
                },
              },
              '*'
            );
            window.parent.postMessage({ type: SpreadsheetMessage.DESTROY }, '*');
          } else {
            dispatch({ type: ActionType.SET_IMPORT_ERROR, error: ImportErrors.SS_API_ERROR });
          }
        }
      },
      onError: () => {
        dispatch({ type: ActionType.SET_IMPORT_ERROR, error: ImportErrors.GENERIC });
      },
    }
  );

  return { mutation };
};

export default useImport;
