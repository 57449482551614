// Copyright ID Business Solutions Ltd. 2023

import styled from 'styled-components';

export const CenteredContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding-left: 10px;
  padding-right: 10px;
  overflow-y: auto;
`;

export const ContentWarningMessage = styled.div`
  margin-top: 10px;
  width: 200px;
  text-align: center;
  color: var(--color-neutral-600);
`;
