// Copyright ID Business Solutions Ltd. 2024

import React from 'react';
import ConfirmImportDialog from './ConfirmImportDialog/ConfirmImportDialog';
import ConfirmClearDataDialog from './ConfirmClearDataDialog/ConfirmClearDataDialog';
import { useApplicationState } from '../../state/state';
import { ImportMutation, ClearDataMutation } from '../../types/shared';

type ConfirmDialogsParamaters = {
  importMutation: ImportMutation;
  clearDataMutation: ClearDataMutation;
};

const ConfirmDialogs = ({ clearDataMutation, importMutation }: ConfirmDialogsParamaters) => {
  const { state } = useApplicationState();

  if (state.confirmImportDialogDetails?.visible) {
    if (state.confirmImportDialogDetails?.clearData) {
      return <ConfirmClearDataDialog mutation={clearDataMutation} />;
    }
    return <ConfirmImportDialog mutation={importMutation} />;
  }
  return <></>;
};

export default ConfirmDialogs;
