/* eslint-disable max-lines */
// Copyright ID Business Solutions Ltd. 2023

import {
  BatchApi,
  BatchInfo,
  CellDataRequest,
  SpreadsheetRequest,
  SsResponseColumns,
  StructureResponse,
  StructureRetrievalRequest,
} from '../../types/spreadsheet';
import { SelectedStep } from '../../types/shared';

import tableMappingsJson from './TableMapping.json';
import {
  CatalogPropertyData,
  CatalogTermData,
  emptyExtendedCatalogTuple,
  emptyExtendedTupleContent,
  ExtendedCatalogTuple,
  ExtendedTupleContent,
  processCatalogData,
  TupleData,
} from '../CatalogUtils/CatalogUtilities';
import { ParameterData, ProcessStepData, processStepDataToParameterData } from '../ParameterUtils';
import { MultiSpecLimitData, specLimitToParameterData } from '../SpecLimitUtils';

const SPREADSHEET_MESSAGE_PREFIX = 'spreadsheetview';
export enum SpreadsheetMessage {
  SHOW = `${SPREADSHEET_MESSAGE_PREFIX}:show`,
  READY = `${SPREADSHEET_MESSAGE_PREFIX}:ready`,
  INIT = `${SPREADSHEET_MESSAGE_PREFIX}:init`,
  DESTROY = `${SPREADSHEET_MESSAGE_PREFIX}:destroy`,
  CURRENT_CELL = `${SPREADSHEET_MESSAGE_PREFIX}:selection:current-cell`,
  SELECTION_CHANGED = `${SPREADSHEET_MESSAGE_PREFIX}:selection:changed`,
  SUCCESS = `${SPREADSHEET_MESSAGE_PREFIX}:success`,
}

enum SpreadsheetApiId {
  DATA = 'table.data',
  DATA_SET = 'table.data.set',
  DATA_CLEAR = 'table.data.clear',
  STRUCTURE = 'table.structure',
  STRUCTURE_CHANGE = 'table.structure.change',
}

// type definitions for the table mappings
type TableDefinitions = {
  tables: {
    [index: string]: TableDefinition;
  };
};

export type TableDefinition = {
  tableName: string;
  mapping: ParameterMappings;
};

type ParameterMappings = {
  [index: string]: ParameterMapping;
};

export type ParameterMapping = {
  key: string;
  subkey?: string;
  staticKey?: string;
  subTable?: string;
};

export type ReferenceData = {
  staticValues: CellValues;
  lookupData: CellData;
  catalogLookupData: CellData;
  catalogPropertiesData: CellData;
  indexes: SSIndexes;
};

type SpreadsheetDataQuery = {
  table: string;
  range: string;
};

export type CellValues = {
  [index: string]: CellValue;
};

export type CellValue = StringValue | NumericValue | DateValue;

export type StringValue = {
  string: string;
};

type NumericValue = {
  number: number;
};

type DateValue = {
  datetime: string;
};

export type CellData = Array<CellValues>;
export type SSLookupData = Array<CellData>;
type SSData = CellData | { lookupData: CellData };

type SSObjectData = {
  [index: string]: SSData;
};

export type SSIndexes = {
  [index: string]: Array<string>;
};

interface ExternalData {
  existingTables: StructureResponse;
  staticValues: CellValues;
  processDataArray: Array<ProcessStepData>;
  specLimitsData: MultiSpecLimitData;
  selectedSteps: Array<SelectedStep>;
  catalogPropertyData: CatalogPropertyData;
  catalogTermData: CatalogTermData;
  tupleGuids: Array<string>;
}

interface DataToConvert extends ExternalData {
  indexes: SSIndexes;
}

export interface BatchOptions extends ExternalData {
  stepCount: number;
}

export type SpreadsheetDataQueries = Array<SpreadsheetDataQuery>;

type ParameterInfo = {
  convertedDataObject: SSObjectData;
  data: Array<ParameterData>;
  tableDefinition: TableDefinition;
  currentColumns: Array<string>;
  referenceData: ReferenceData;
};

type LookupIndexData = {
  indexForIdx: number;
  lookupIndexValue: string;
  inputIndexName: string;
  indexes: SSIndexes;
};

const tableMappings: TableDefinitions = tableMappingsJson;
export const parameterMappings = tableMappings.tables.parameters;
export const specMappings = tableMappings.tables.spec_limits;

const lookupsMappings = tableMappings.tables.lookups;
const lookupParameterName = Object.keys(lookupsMappings.mapping)[0]; // the one and only!

const catalogLookupsMappings = tableMappings.tables.catalogLookups;
const catalogRows = Object.keys(catalogLookupsMappings.mapping);

const catalogPropertiesMappings = tableMappings.tables.catalogProperties;
const propertyRows = Object.keys(catalogPropertiesMappings.mapping);

export const PROCESS_TABLE_NAME = parameterMappings.tableName;
export const LIMITS_TABLE_NAME = specMappings.tableName;
export const LOOKUP_TABLE_NAME = lookupsMappings.tableName;
export const CATALOG_LOOKUP_TABLE_NAME = catalogLookupsMappings.tableName;
const CATALOG_PROPERTIES_TABLE_NAME = catalogPropertiesMappings.tableName;

const missingValue: StringValue = { string: '' };

export const allBackingTableQueeeries = [
  {
    table: PROCESS_TABLE_NAME,
    range: '',
  },
  {
    table: LIMITS_TABLE_NAME,
    range: '',
  },
  {
    table: LOOKUP_TABLE_NAME,
    range: '',
  },
];

export class SpreadsheetUtils {
  /**
   * Generate the object to use for a SpreadSheet batch update request
   *
   * Limit the parameter and spec limits to the existing tables.
   * Convert the parameter and spec limits responses into a SS compatible object
   * Process the tables from that object into the batch update request using the step count
   * to create the audit log message.
   */
  static generateBatchUpdateRequest(batchOptions: BatchOptions): SpreadsheetRequest<BatchInfo> {
    const { stepCount } = batchOptions;
    const indexes: SSIndexes = {};
    const convertedDataTables = convertToSSObject({
      ...batchOptions,
      indexes,
    });
    const request: SpreadsheetRequest<BatchInfo> = createBatchRequest(
      'Process Import',
      stepCount > 1
        ? `Imported parameter details for ${stepCount} Process Steps`
        : 'Imported parameter details for 1 Process Step',
      'Process Import Spreadsheet'
    );
    Object.entries(convertedDataTables).forEach(([tableName, tableData]) => {
      addTableTo(request, tableName, tableData, indexes);
    });
    return request;
  }

  /**
   * Generate the object to use for a SpreadSheet batch update clear request
   */
  static generateBatchClearRequest(): SpreadsheetRequest<BatchInfo> {
    const request: SpreadsheetRequest<BatchInfo> = createBatchRequest(
      'Process Clear',
      'Cleared parameter details from background tables',
      'Process Clear Spreadsheet'
    );

    addTableClearTo(request, PROCESS_TABLE_NAME, false);
    addTableClearTo(request, LIMITS_TABLE_NAME, false);
    addTableClearTo(request, LOOKUP_TABLE_NAME, true);
    addTableClearTo(request, CATALOG_LOOKUP_TABLE_NAME, true);
    addTableClearTo(request, CATALOG_PROPERTIES_TABLE_NAME, true);

    return request;
  }

  static formCellTableDataRequest(queries: SpreadsheetDataQueries): SpreadsheetRequest<CellDataRequest> {
    return {
      'batch-request': [
        {
          version: '1.0',
        },
        [
          getApiIdObject(SpreadsheetApiId.DATA),
          {
            data: {
              queries,
            },
          },
        ],
      ],
    };
  }

  static formTableColumnsRequest(): SpreadsheetRequest<StructureRetrievalRequest> {
    return {
      'batch-request': [
        {
          version: '1.0',
        },
        [
          getApiIdObject(SpreadsheetApiId.STRUCTURE),
          {
            data: {
              tables: [PROCESS_TABLE_NAME, LIMITS_TABLE_NAME],
            },
          },
        ],
      ],
    };
  }

  /**
   * @param columns The columns of the table, in the format the SS API returns
   * @returns true if the table is empty (i.e. all values are empty). false otherwise
   */
  static isTableEmpty(columns: SsResponseColumns = []) {
    return !columns.some((column) =>
      Object.entries(column).some(([columnName, columnValue]) => {
        if (columnName === `${PROCESS_TABLE_NAME}_idx` || columnName === `${LIMITS_TABLE_NAME}_idx`) {
          return false;
        }
        if (columnValue.string) {
          return true;
        }
        if (columnValue.number !== null && columnValue.number !== undefined) {
          return true;
        }
        return false;
      })
    );
  }
}

const getApiIdObject = (id: string): BatchApi => ({
  'api-id': id,
  'api-version': '1.0',
});

const createBatchRequest = (type: string, description: string, location: string): SpreadsheetRequest<BatchInfo> => {
  return {
    'batch-request': [
      {
        version: '1.0',
        options: {
          force: true, // ensure update can change table structure
          auditEvent: {
            type,
            description,
            location,
          },
        },
      },
      [],
    ],
  };
};

const ensureLookupTable = (lookupData: CellData, tableName: string, indexes: SSIndexes): CellData => {
  if (lookupData.length > 0) {
    return lookupData;
  }
  makeEmptyLookupTable(lookupData, tableName, indexes);
  return lookupData;
};

/**
 * Convert the api responses into a suitable data object to create a batch request
 *
 * Convert getParamters response object into array of parameter information (including process step heirachy)
 * Use the mapping table to format that array into the data object
 * Convert the getMultiSpecLimits response object into array of spec limit information
 * Use the mapping table to format that array into the data object
 * If a lookup table was not generated by the above, create dummy one to clear any existing lookup table
 * Add the lookup table to the data object
 */
const convertToSSObject = (dataToConvert: DataToConvert): SSObjectData => {
  const {
    existingTables,
    staticValues,
    indexes,
    processDataArray,
    specLimitsData,
    selectedSteps,
    catalogPropertyData,
    catalogTermData,
    tupleGuids,
  } = dataToConvert;
  const convertedDataObject: SSObjectData = {};
  const lookupData: CellData = [];
  const catalogLookupData: CellData = [];
  const catalogPropertiesData: CellData = [];
  const referenceData = {
    staticValues,
    lookupData,
    catalogLookupData,
    catalogPropertiesData,
    indexes,
  };

  const processColumns = existingTables.tables[PROCESS_TABLE_NAME].dimensions[0].itemNames.map((column) =>
    column.replace(`:${parameterMappings.tableName}`, '')
  );

  const limitsColumns = existingTables.tables[LIMITS_TABLE_NAME].dimensions[0].itemNames.map((column) =>
    column.replace(`:${specMappings.tableName}`, '')
  );

  const { termPropertiesLookup, tupleProperties } = processCatalogData(catalogPropertyData, catalogTermData);

  const parameterData: Array<ParameterData> = processDataArray
    ? processStepDataToParameterData(processDataArray, selectedSteps, termPropertiesLookup)
    : [{}];
  addParameterInfo({
    convertedDataObject,
    data: parameterData,
    tableDefinition: parameterMappings,
    currentColumns: processColumns,
    referenceData,
  });

  // spread multiple limit_ranges over multiple ParameterData objects
  const specLimitData = specLimitsData ? specLimitToParameterData(specLimitsData) : [{}];
  addParameterInfo({
    convertedDataObject,
    data: specLimitData,
    tableDefinition: specMappings,
    currentColumns: limitsColumns,
    referenceData,
  });

  addAlternativeTuplesInfo(tupleGuids, tupleProperties, referenceData);

  convertedDataObject[LOOKUP_TABLE_NAME] = { lookupData: ensureLookupTable(lookupData, LOOKUP_TABLE_NAME, indexes) };
  convertedDataObject[CATALOG_LOOKUP_TABLE_NAME] = {
    lookupData: ensureLookupTable(catalogLookupData, CATALOG_LOOKUP_TABLE_NAME, indexes),
  };
  convertedDataObject[CATALOG_PROPERTIES_TABLE_NAME] = {
    lookupData: ensureLookupTable(catalogPropertiesData, CATALOG_PROPERTIES_TABLE_NAME, indexes),
  };

  return convertedDataObject;
};

/**
 * Take the parameter data items
 *    create spreadsheet data object to hold all the cell updates
 *    create any necessary lookup cell data
 *    add into the main spreadsheet data object
 */
const addParameterInfo = (paramaterInfo: ParameterInfo): void => {
  const { convertedDataObject, data, tableDefinition, currentColumns, referenceData } = paramaterInfo;
  const { indexes } = referenceData;
  const { tableName, mapping } = tableDefinition;
  const parameterKeys = Object.keys(mapping);
  let counter = 1;
  const tableIndex = createIdxName(tableName, indexes);
  const convertedData = data.map((param) => {
    const returnObject: CellValues = {};

    parameterKeys.forEach((parameterKey) => {
      if (currentColumns.includes(parameterKey)) {
        returnObject[`${parameterKey}:${tableName}`] = getValue(mapping[parameterKey], param, referenceData);
      }
    });
    const index = (counter++).toString();
    returnObject[tableIndex] = { string: index };
    indexes[tableIndex].push(index);
    return returnObject;
  });
  if (convertedData.length === 0) {
    // no data, so blank out the table
    const dummyData: CellValues = {};
    dummyData[tableIndex] = { string: '1' };
    indexes[tableIndex].push('1');
    convertedData.push(dummyData);
  }
  convertedDataObject[tableName] = convertedData;
};

const addAlternativeTuplesInfo = (
  tupleGuids: Array<string>,
  tupleProperties: TupleData,
  referenceData: ReferenceData
) => {
  if (tupleGuids) {
    tupleGuids.forEach((tupleGuid) => {
      const properties = tupleProperties[tupleGuid];
      if (properties) {
        arrayToCatalogPropertiesCells(tupleGuid, properties, referenceData);
      }
    });
  }
};

/**
 * Take the array of Cell values, and the table name and add them into the Spreadsheet request object
 *
 * @param request - Spreadsheet request object to add into
 * @param tableName - the specific table to extract from the converted data
 * @param convertedData - array of all Cell Values
 * @param indexes - array of all Cell Values
 */
const addTableTo = (
  request: SpreadsheetRequest<BatchInfo>,
  tableName: string,
  inputData: SSData,
  indexes: SSIndexes
): void => {
  const isLookUp = !Array.isArray(inputData);
  const convertedData = isLookUp ? inputData.lookupData : inputData;

  const tableData = request['batch-request'][1];
  // add structure change
  const tableIndex = `${tableName}_idx`;
  const tableIndexes = {
    [tableIndex]: {
      itemNames: indexes[tableIndex],
    },
  };
  if (isLookUp) {
    const tableLookupIndex = `${tableName}_input`;
    tableIndexes[tableLookupIndex] = {
      itemNames: indexes[tableLookupIndex],
    };
  }
  tableData.push(getApiIdObject(SpreadsheetApiId.STRUCTURE_CHANGE));
  tableData.push({
    data: {
      tables: {
        [tableName]: tableIndexes,
      },
    },
  });
  // add cell data
  tableData.push(getApiIdObject(SpreadsheetApiId.DATA_SET));
  tableData.push({
    data: {
      tables: [{ name: tableName, range: tableName }, isLookUp ? convertedData.flat() : convertedData],
    },
  });
};

/**
 * Take the table name and add details to clear it into the Spreadsheet request object
 *
 * @param request - Spreadsheet request object to add into
 * @param tableName - the specific table to extract from the converted data
 * @param isLookUp - true iff look up table
 */
const addTableClearTo = (request: SpreadsheetRequest<BatchInfo>, tableName: string, isLookUp: boolean): void => {
  const tableData = request['batch-request'][1];
  // add structure change
  const tableIndexes = {
    [`${tableName}_idx`]: {
      itemNames: ['1'],
    },
  };
  if (isLookUp) {
    tableIndexes[`${tableName}_input`] = {
      itemNames: [getLookupIndexValue(tableName, 1)],
    };
  }
  tableData.push(getApiIdObject(SpreadsheetApiId.STRUCTURE_CHANGE));
  tableData.push({
    data: {
      tables: {
        [tableName]: tableIndexes,
      },
    },
  });
  // add table clear
  tableData.push(getApiIdObject(SpreadsheetApiId.DATA_CLEAR));
  tableData.push({
    data: {
      ranges: [
        {
          table: tableName,
        },
      ],
    },
  });
};

const createIdxName = (tableName: string, indexes: SSIndexes) => {
  const tableIndex = `${tableName}_idx`;
  if (!indexes[tableIndex]) {
    indexes[tableIndex] = [];
  }
  return tableIndex;
};

const createInputIndexName = (tableName: string, indexes: SSIndexes) => {
  const tableIndex = `${tableName}_input`;
  if (!indexes[tableIndex]) {
    indexes[tableIndex] = [];
  }
  return tableIndex;
};

/**
 * Take a parameter object and its relevant mapping information and create a Cell value object for it
 */
const getValue = (mapping: ParameterMapping, param: object, referenceData: ReferenceData): CellValue => {
  const { key, subkey, staticKey, subTable } = mapping;
  if (staticKey) {
    return referenceData.staticValues[staticKey];
  }
  const value = param[key];
  if (value === null || value === undefined) {
    return missingValue;
  }
  if (Array.isArray(value)) {
    if (value.length === 0) {
      return missingValue;
    }
    return generateArrayReturnValue(referenceData, value, subTable, subkey);
  }
  const returnValue = subkey ? value[subkey] : value;
  if (returnValue === null || returnValue === undefined) {
    return missingValue;
  }
  return generateReturnValue(returnValue);
};

const generateArrayReturnValue = (
  referenceData: ReferenceData,
  value: Array<any>,
  subTable?: string,
  subkey?: string
): CellValue => {
  if (subTable === 'catalogLookups') {
    const inputIndexValue = arrayToCatalogLookupCells(value, referenceData);
    return generateReturnValue(inputIndexValue);
  }
  if (subTable === 'lookups') {
    const inputIndexValue = arrayToLookupCells(value, referenceData, subkey);
    return generateReturnValue(inputIndexValue);
  }
  return missingValue;
};

const getLookupIndexValue = (tableName: string, index: number): string => {
  return `${tableName}_index${index}`;
};

export const arrayToCatalogLookupCells = (
  tuples: Array<ExtendedCatalogTuple>,
  referenceData: ReferenceData
): string => {
  const { catalogLookupData, indexes } = referenceData;
  if (tuples.length === 0) {
    return missingValue.string;
  }
  const inputIndexName = createInputIndexName(CATALOG_LOOKUP_TABLE_NAME, indexes);
  const tableIndex = indexes[inputIndexName];
  const lookupIndexValue = getLookupIndexValue(CATALOG_LOOKUP_TABLE_NAME, tableIndex.length + 1);
  tableIndex.push(lookupIndexValue);

  tuples.forEach((tuple, indexForIdx) => {
    makeCatalogLookupTableCells(
      catalogLookupData,
      tuple,
      { indexForIdx, lookupIndexValue, inputIndexName, indexes },
      CATALOG_LOOKUP_TABLE_NAME
    );
  });
  return lookupIndexValue;
};

export const arrayToCatalogPropertiesCells = (
  lookupIndexValue: string,
  tuples: Array<ExtendedTupleContent>,
  referenceData: ReferenceData
): void => {
  const { catalogPropertiesData, indexes } = referenceData;
  if (tuples.length === 0) {
    return;
  }
  const inputIndexName = createInputIndexName(CATALOG_PROPERTIES_TABLE_NAME, indexes);
  const tableIndex = indexes[inputIndexName];
  tableIndex.push(lookupIndexValue);

  tuples.forEach((tuple, indexForIdx) => {
    makeCatalogLookupTableCells(
      catalogPropertiesData,
      tuple,
      { indexForIdx, lookupIndexValue, inputIndexName, indexes },
      CATALOG_PROPERTIES_TABLE_NAME
    );
  });
};

export const arrayToLookupCells = (
  values: Array<string | object>,
  referenceData: ReferenceData,
  subKey?: string
): string => {
  const { lookupData, indexes } = referenceData;
  if (values.length === 0) {
    return missingValue.string;
  }
  const inputIndexName = createInputIndexName(LOOKUP_TABLE_NAME, indexes);
  const tableIndex = indexes[inputIndexName];
  const lookupIndexValue = getLookupIndexValue(LOOKUP_TABLE_NAME, tableIndex.length + 1);
  tableIndex.push(lookupIndexValue);

  values.forEach((value, indexForIdx) => {
    makeLookupTableCell(lookupData, value, subKey, { indexForIdx, lookupIndexValue, inputIndexName, indexes });
  });
  return lookupIndexValue;
};

const generateReturnValue = (returnValue: any): CellValue => {
  if (!returnValue) {
    return missingValue;
  }
  switch (typeof returnValue) {
    case 'string':
      return { string: returnValue };
    case 'number':
      return { number: returnValue };
    case 'boolean':
      return { number: returnValue ? 1 : 0 };
    default:
      return { string: `unexpected data type: ${typeof returnValue}` };
  }
};

const addIdx = (cell: CellValues, tableName: string, indexes: SSIndexes, indexForIdx: number) => {
  const idxValue = (indexForIdx + 1).toString();
  const idxName = createIdxName(tableName, indexes);
  const tableIndex = indexes[idxName];
  if (!tableIndex.includes(idxValue)) {
    tableIndex.push(idxValue);
  }
  cell[idxName] = generateReturnValue(idxValue);
};

const makeLookupTableCell = (
  cells: CellData,
  value: string | object,
  subKey: string | undefined,
  indexData: LookupIndexData
) => {
  const { indexForIdx, lookupIndexValue, inputIndexName, indexes } = indexData;

  const cell = {};
  const generatedReturnValue = generateReturnValue(subKey ? value[subKey] : value);
  cell[`${lookupParameterName}:${LOOKUP_TABLE_NAME}`] = generatedReturnValue;
  cell[inputIndexName] = generateReturnValue(lookupIndexValue);
  addIdx(cell, LOOKUP_TABLE_NAME, indexes, indexForIdx);
  cells.push(cell);
};

const makeCatalogLookupTableCells = (cells: CellData, tuple: object, indexData: LookupIndexData, tableName: string) => {
  const { indexForIdx, lookupIndexValue, indexes } = indexData;
  const inputIndexName = createInputIndexName(tableName, indexes);
  const rows = tableName === CATALOG_LOOKUP_TABLE_NAME ? catalogRows : propertyRows;
  const mappings = tableName === CATALOG_LOOKUP_TABLE_NAME ? catalogLookupsMappings : catalogPropertiesMappings;

  const cell = {};
  cell[inputIndexName] = generateReturnValue(lookupIndexValue);
  addIdx(cell, tableName, indexes, indexForIdx);
  rows.forEach((catalogLookupParameterName) => {
    const key = mappings.mapping[catalogLookupParameterName].key;
    const generatedReturnValue = generateReturnValue(tuple[key]);
    cell[`${catalogLookupParameterName}:${tableName}`] = generatedReturnValue;
  });
  cells.push(cell);
};

const makeEmptyLookupTable = (cells: CellData, tableName: string, indexes: SSIndexes) => {
  const referenceData: ReferenceData = {
    staticValues: {},
    lookupData: [],
    catalogLookupData: [],
    catalogPropertiesData: [],
    indexes,
  };
  if (tableName === LOOKUP_TABLE_NAME) {
    referenceData.lookupData = cells;
    arrayToLookupCells([missingValue.string], referenceData);
  } else if (tableName === CATALOG_LOOKUP_TABLE_NAME) {
    referenceData.catalogLookupData = cells;
    arrayToCatalogLookupCells([emptyExtendedCatalogTuple], referenceData);
  } else {
    referenceData.catalogPropertiesData = cells;
    arrayToCatalogPropertiesCells('pims_catalog_properties_input1', [emptyExtendedTupleContent], referenceData);
  }
};
