// Copyright ID Business Solutions Ltd. 2024

import { useIntlMessage } from '@idbs/idbs-react-hooks';
import { ModalDialog } from '@idbs/idbs-react-components';
import { AttentionIcon } from '@idbs/idbs-react-icon';
import React from 'react';
import { SpreadsheetMessage } from '../../utils/SpreadsheetUtils/SpreadsheetUtils';
import { DialogBodyTitle, DialogBodyMessage, StyledButton, Flexbox } from './VerifyDialog.style';

const onCancel = () => {
  window.parent.postMessage({ type: SpreadsheetMessage.DESTROY }, '*');
};

const VerifyDialog = () => {
  const intlMessage = useIntlMessage();
  return (
    <ModalDialog onHide={onCancel} testId='verify-dialog' visible>
      <ModalDialog.Header>{intlMessage('app.no.data.title')}</ModalDialog.Header>
      <ModalDialog.Body>
        <Flexbox>
          <AttentionIcon
            color={'var(--color-red-600)'}
            width='30px'
            height='30px'
            data-testid={'verify-icon'}
            className='preset-icon'
          />
          <div data-testid={'verify-dialog-content'}>
            <DialogBodyTitle>{intlMessage('app.no.product.header')}</DialogBodyTitle>
            <DialogBodyMessage>{intlMessage('app.not.verified.body')}</DialogBodyMessage>
          </div>
        </Flexbox>
      </ModalDialog.Body>
      <ModalDialog.Footer>
        <StyledButton large emphasis='secondary' testId='close-dialog-button' onClick={onCancel}>
          {intlMessage('app.no.data.close')}
        </StyledButton>
      </ModalDialog.Footer>
    </ModalDialog>
  );
};

export { VerifyDialog };
