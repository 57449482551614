// Copyright ID Business Solutions Ltd. 2023

import { useIntlMessage } from '@idbs/idbs-react-hooks';
import { ConfirmDialog } from '@idbs/idbs-react-components';
import React from 'react';

type ImportWarningProps = {
  onClose: () => void;
};

const ImportWarningDialog = ({ onClose }: ImportWarningProps) => {
  const intlMessage = useIntlMessage();
  const title = intlMessage('app.import.failed');
  const header = intlMessage('app.import.none.reason');
  const body = intlMessage('app.import.none.response');
  const closeText = intlMessage('app.import.none.close');
  return (
    <ConfirmDialog
      body={body}
      header={header}
      onClose={onClose}
      icon='attention'
      title={title}
      closeBtnText={closeText}
      submitBtnText=''
      submitBtnPosition='right'
      testId='import-warning-dialog'
      visible
    />
  );
};

export { ImportWarningDialog };
