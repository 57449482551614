// Copyright ID Business Solutions Ltd. 2024

import React from 'react';
import { useIntlMessage } from '@idbs/idbs-react-hooks';
import { Button } from '@idbs/idbs-react-components';

import { ClearDataButtonWrapper } from './ClearDataButton.style';

type ClearDataButtonProps = {
  onClick: () => void;
  disabled?: boolean;
};

const ClearDataButton = ({ onClick, disabled }: ClearDataButtonProps) => {
  const intlMessage = useIntlMessage();

  return (
    <ClearDataButtonWrapper data-testid='clear-data-button'>
      <Button emphasis='secondary' onClick={onClick} disabled={disabled}>
        {intlMessage('app.clear.data.button')}
      </Button>
    </ClearDataButtonWrapper>
  );
};

export { ClearDataButton };
