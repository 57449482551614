// Copyright ID Business Solutions Ltd. 2024

import React from 'react';
import { useIntlMessage } from '@idbs/idbs-react-hooks';
import { ActionType, useApplicationState } from '../../../state/state';
import ConfirmActionDialog from '../ConfirmActionDialog/ConfirmActionDialog';
import { ClearDataMutation } from '../../../types/shared';

type ConfirmClearDataDialogParamaters = {
  mutation: ClearDataMutation;
};

const ConfirmClearDataDialog = ({ mutation }: ConfirmClearDataDialogParamaters) => {
  const intlMessage = useIntlMessage();
  const { dispatch } = useApplicationState();

  const title = intlMessage('confirm.clear.data.parameters');
  const header = intlMessage('confirm.clear.data.dialog.header');
  const text = intlMessage('confirm.clear.data.dialog.text');
  const closeText = intlMessage('confirm.clear.data.dialog.cancel');
  const submitText = intlMessage('confirm.clear.data.dialog.submit');
  const testId = 'confirm-clear-data-dialog';

  const onConfirm = () => {
    dispatch({ type: ActionType.HIDE_CLEAR_DATA_DIALOG });
    mutation.mutate({});
  };

  const onCancel = () => {
    dispatch({ type: ActionType.HIDE_CLEAR_DATA_DIALOG });
  };

  return (
    <ConfirmActionDialog
      onCancel={onCancel}
      onConfirm={onConfirm}
      title={title}
      header={header}
      text={text}
      closeText={closeText}
      submitText={submitText}
      testId={testId}
    />
  );
};

export default ConfirmClearDataDialog;
