// Copyright ID Business Solutions Ltd. 2024

import styled from 'styled-components';

export const DialogBody = styled.div`
  display: flex;
`;

export const DialogIcon = styled.div`
  color: var(--color-yellow-600);
  margin-right: 12px;
`;

export const DialogTextContainer = styled.div``;

export const DialogTitle = styled.h3`
  padding: 0;
  margin: 0 0 6px;
  color: var(--theme-main-700);
  font-weight: 500;
`;

export const DialogSubHeader = styled.p`
  color: var(--theme-main-700);
  margin: 3px 0px;
`;

export const DialogText = styled.p`
  color: var(--color-neutral-700);
  margin: 3px 0px;
`;
