// Copyright ID Business Solutions Ltd. 2023

import React from 'react';
import { Button } from '@idbs/idbs-react-components';
import { CrossIcon } from '@idbs/idbs-react-icon';
import { UnselectButtonWrapper } from './UnselectButton.style';

type ImportButtonProps = {
  onClick: () => void;
  disabled?: boolean;
};

const UnselectButton = ({ onClick, disabled }: ImportButtonProps) => {
  return (
    <UnselectButtonWrapper data-testid='unselect-button'>
      <Button icon={<CrossIcon />} emphasis='ghost' onClick={onClick} disabled={disabled} />
    </UnselectButtonWrapper>
  );
};

export { UnselectButton };
