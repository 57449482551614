// Copyright ID Business Solutions Ltd. 2023

import styled from 'styled-components';

export const Wrapper = styled.div`
  width: fit-content;
  max-width: ${(props) => props.maxWidth};
  position: relative;
  display: inline-block;
  width: 100%;

  button {
    width: 100%;
    box-sizing: border-box;

    > span {
      width: 100%;
    }

    svg {
      margin-left: auto;
    }
  }
`;

// Stop TypeScript getting upset is we use a <div> </div> instead!
export const ButtonWrapper = styled.div``;

export const StyledDropdown = styled.div`
  z-index: 1;
  display: inline-block;
  min-width: 200px;
  max-height: ${(props) => props.maxHeight};
  max-width: ${(props) => props.maxWidth};
  overflow-y: ${(props) => (props.maxHeight === 'none' ? 'hidden' : 'auto')};
  overflow-x: hidden;
  border-radius: 1px;
  box-shadow: 0 1px 3px 0 rgb(13 14 16 / 30%);
  outline: none;
  background-color: white;

  /*
   * Initial styling to allow the size of the menu to be measured, once we know
   * where it is going to be shown it is positioned in the code (see positionMenu
   * in DropDown.js) and then made visible.
   */
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;

  .tree-item .title svg {
    pointer-events: none;
  }
`;

export const DropdownButtonText = styled.span`
  margin-right: 5px; /* 5px from down icon to button edge */
  max-width: calc(100% - 21px); /* 21px = 5px from text to down icon plus width of down icon */
  display: inline !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ProductSelectDropdownWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  margin-left: 10px;
  margin-right: 10px;
  .product-select-dropdown {
    color: var(--color-neutral-700);
  }
  [role='tree'] {
    font-weight: 700;
  }
  [role='group'] {
    font-weight: 400;
  }
  button {
    color: var(--color-neutral-700);
  }
`;
