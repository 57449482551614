// Copyright ID Business Solutions Ltd. 2023

import { LoadingIndicator } from '@idbs/idbs-react-components';
import { useIntlMessage } from '@idbs/idbs-react-hooks';
import React from 'react';

import { ClearDataButton } from './ClearDataButton/ClearDataButton';

import { ActionType, useApplicationState } from '../../state/state';
import { ClearDataMutation } from '../../types/shared';

type ClearDataProps = {
  mutation: ClearDataMutation;
};

const ClearData = ({ mutation }: ClearDataProps) => {
  const intlMessage = useIntlMessage();
  const { dispatch } = useApplicationState();

  const clearData = () => {
    dispatch({ type: ActionType.SHOW_CLEAR_DATA_DIALOG });
  };

  return (
    <>
      <ClearDataButton onClick={clearData} />
      <LoadingIndicator visible={mutation.isLoading} text={intlMessage('clearing.data')} />
    </>
  );
};

export { ClearData };
