// Copyright ID Business Solutions Ltd. 2023

import React, { useCallback, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';

import { useIntlMessage, useOnClickOutside } from '@idbs/idbs-react-hooks';

import { useApplicationState } from '../../../state/state';
import { queryKeySites } from '../../../App';
import { Site } from '../../../types/shared';

import {
  ProductSelectDropdownWrapper,
  DropdownButtonText,
  StyledDropdown,
  Wrapper,
  ButtonWrapper,
} from './ProductSelectDropdown.style';
import { footerHeight } from '../../Footer/Footer.style';

import { CaretDownIcon } from '@idbs/idbs-react-icon';
import { Button } from '@idbs/idbs-react-components';
import { SiteProductTree } from '../SiteProductTree/SiteProductTree';
import { SitesToDisplayTree, DisplayTree } from '../SiteProductTree/DisplayTree';

// Figure out where the tree should be positioned, below and left aligned,
// but ensure does not disappear off the edge of the screen when the button element is at the edges.
const positionTree = (buttonElement, treeElement) => {
  const buttonDimensions = buttonElement.getBoundingClientRect();
  const treeDimensions = treeElement.getBoundingClientRect();

  treeElement.style.left = '0px';
  treeElement.style.top = '100%';
  treeElement.style.position = 'absolute';
  treeElement.style.visibility = 'visible';
  if (buttonDimensions.width > treeDimensions.width) {
    treeElement.style.width = `${buttonDimensions.width}px`;
  }
};

const getTree = (sites: undefined | Array<Site>): DisplayTree => {
  let tree: DisplayTree = [];
  if (sites) {
    tree = SitesToDisplayTree(sites);
  }
  return tree;
};

const getDropdownText = (tree, selectedProductId, defaultText) => {
  let dropdownText = defaultText;
  if (selectedProductId) {
    tree.forEach((site) => {
      site.children.forEach((child) => {
        if (child?.children) {
          child.children.forEach((product) => {
            if (product.id === selectedProductId) {
              dropdownText = `${site.name} - ${child.name} - ${product.name}`;
            }
          });
        } else if (child.id === selectedProductId) {
          dropdownText = `${site.name} - ${child.name}`;
        }
      });
    });
  }
  return dropdownText;
};

const ProductSelectDropdown = () => {
  const [open, setOpen] = useState(false);
  const [positioned, setPositioned] = useState(false);

  const buttonWrapperRef = useRef();
  const dropdownWrapperRef = useRef();

  const {
    state: { selectedProductId },
  } = useApplicationState();
  const intlMessage = useIntlMessage();
  const queryClient = useQueryClient();
  const sites: Array<Site> | undefined = queryClient.getQueryData(queryKeySites);
  const tree = getTree(sites);
  const dropdownText = getDropdownText(tree, selectedProductId, intlMessage('app.select.product'));
  const headerHeight = '40px'; // fixed by ActionPanel in Header component
  const maxHeight = `calc(100vh - ${headerHeight} - ${footerHeight})`;

  // use a callback ref to avoid issues with having ref.current in useEffect deps array
  const setRef = useCallback(
    (node) => {
      if (node && !positioned) {
        setPositioned(true);
        positionTree(buttonWrapperRef.current, node);
      }
    },
    [positioned]
  );

  const onClick = (e) => {
    setPositioned(false);
    setOpen(!open);
  };

  const closeMe = () => {
    setOpen(!open);
  };

  useOnClickOutside(dropdownWrapperRef, () => {
    if (open) {
      closeMe();
    }
  });

  return (
    <>
      <ProductSelectDropdownWrapper ref={dropdownWrapperRef}>
        <Wrapper data-testid='product-select' className='product-select-dropdown' maxHeight={maxHeight} maxWidth='none'>
          <ButtonWrapper ref={buttonWrapperRef}>
            <Button
              emphasis='secondary'
              onClick={onClick}
              aria-haspopup='true'
              disabled={sites && sites.length === 0}
              icon={null}
            >
              <DropdownButtonText>{dropdownText}</DropdownButtonText>
              <CaretDownIcon width={16} />
            </Button>
          </ButtonWrapper>
          {open && (
            <StyledDropdown ref={setRef} tabIndex='0' maxHeight={maxHeight} maxWidth='none'>
              <SiteProductTree treeData={tree} closeDropdown={closeMe} />
            </StyledDropdown>
          )}
        </Wrapper>
      </ProductSelectDropdownWrapper>
    </>
  );
};

export { ProductSelectDropdown };
