// Copyright ID Business Solutions Ltd. 2023

import React from 'react';
import { useIntlMessage } from '@idbs/idbs-react-hooks';

import { ProductSelectWrapper } from './ProductSelect.style';
import { ProductSelectDropdown } from './ProductSelectDropdown/ProductSelectDropdown';

const ProductSelect = () => {
  const intlMessage = useIntlMessage();
  const productPrompt = intlMessage('app.product.prompt');

  return (
    <ProductSelectWrapper data-testid='product-select-prompt'>
      {productPrompt}
      <ProductSelectDropdown />
    </ProductSelectWrapper>
  );
};

export { ProductSelect };
