// Copyright ID Business Solutions Ltd. 2023

import React from 'react';
import { useIntlMessage } from '@idbs/idbs-react-hooks';
import { useApplicationState } from '../../../state/state';
import { ImportErrors } from '../../../hooks/useImport';
import { FullHeightContent } from './FullScreenError.style';
import { GenericError } from '../../GenericError/GenericError';

const FullScreenError = () => {
  const intlMessage = useIntlMessage();
  const {
    state: { importError },
  } = useApplicationState();
  const messages: Array<string> = [];
  if (importError) {
    const importErrors = Object.keys(importError);
    importErrors.forEach((error, index) => {
      const cellValue = importError[error].join(', ');
      switch (error) {
        case ImportErrors.NOT_FOUND.toString():
          messages.push(intlMessage('app.import.could.not.find.step', { stepName: cellValue }));
          break;
        case ImportErrors.MULTIPLE_STEPS_FOUND.toString():
          messages.push(intlMessage('app.import.multiple.steps.found', { stepName: cellValue }));
          break;
        default:
          break;
      }
    });
    messages.push(intlMessage('app.import.error.advice'));
  }

  return (
    <FullHeightContent>
      <GenericError iconColour='red' message={messages}></GenericError>
    </FullHeightContent>
  );
};

export default FullScreenError;
