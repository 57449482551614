// Copyright ID Business Solutions Ltd. 2023

import { TableDataResponse, SpreadsheetResponse, StructureResponse } from '../types/spreadsheet';
import type { FoundStep, Site, SortableNamedItem } from '../types/shared';
import { Auth } from '../utils/Auth/Auth';
import { ConfigService } from '../utils/ConfigService/ConfigService';
import { BatchOptions, SpreadsheetDataQueries, SpreadsheetUtils } from '../utils/SpreadsheetUtils/SpreadsheetUtils';
import { ProcessStepData } from '../utils/ParameterUtils';
import { CatalogPropertyData, CatalogTermData } from '../utils/CatalogUtils/CatalogUtilities';
import { MultiSpecLimitData } from '../utils/SpecLimitUtils';

export const getPimsHttpHeaders = () => {
  const header = new Headers({
    Accept: 'application/json',
    Bearer: Auth.getToken()!,
    'Content-Type': 'application/json',
  });
  return header;
};

export const getElnHttpHeaders = () => {
  const header = new Headers({
    Accept: 'application/json',
    Authorization: `Bearer ${Auth.getToken()!}`,
    'Content-Type': 'application/json',
  });
  return header;
};

export function cleanSites(json) {
  /* Global Templates only contain templates and have no id,
    but we require an id in the rest of the code.
    We identify the Global Template by it's missing id
    and give it a unique id (normal id's will not be negative)
    and move any templates (process_tempates) into the products.
  */
  json?.forEach((site) => {
    if (!site?.id) {
      site.id = -1;
      site.products = site.products.concat(site.process_templates);
      site.process_templates = [];
    }
  });
}

export enum RestApiErrorMessage {
  GENERIC = 'Network response was not ok',
  NOT_VERIFIED = 'User not verified',
}

/**
 * For this simple use case, 'fetch' is good enough. For a more complex application consider
 * using a library for Rest calls such as https://github.com/sindresorhus/ky
 */
export class RestApi {
  static async getSites(): Promise<Site[]> {
    const response = await fetch(`${ConfigService.process}/api/v1/sites`, { headers: getPimsHttpHeaders() });
    if (!response.ok) {
      if (response.status === 403) {
        throw new Error(RestApiErrorMessage.NOT_VERIFIED);
      }
      throw new Error(RestApiErrorMessage.GENERIC);
    }
    const json = await response.json();
    cleanSites(json);
    return json;
  }

  static async getSteps(product: number): Promise<SortableNamedItem[]> {
    const response = await fetch(`${ConfigService.process}/api/v1/product/${product}/steps`, {
      headers: getPimsHttpHeaders(),
    });
    if (!response.ok) {
      throw new Error(RestApiErrorMessage.GENERIC);
    }
    const json = await response.json();
    return json;
  }

  static async getSubSteps(stepId: number): Promise<SortableNamedItem[]> {
    const response = await fetch(`${ConfigService.process}/api/v1/step/${stepId}/steps`, {
      headers: getPimsHttpHeaders(),
    });
    if (!response.ok) {
      throw new Error(RestApiErrorMessage.GENERIC);
    }
    const json = await response.json();
    return json;
  }

  static async findMultipleSteps(stepNames: Array<string>): Promise<FoundStep[]> {
    if (stepNames.length === 0) {
      return [];
    }
    const headers = getPimsHttpHeaders();
    const url = `${ConfigService.process}/api/v1/steps`;
    const body = { step_names: stepNames };

    const response = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      throw new Error(RestApiErrorMessage.GENERIC);
    }
    const json = await response.json();
    return json;
  }

  static async getParameters(stepIds: Array<number>): Promise<Array<ProcessStepData>> {
    const step_ids = stepIds.join(',');
    const response = await fetch(`${ConfigService.process}/api/v1/parameters?step_ids=${step_ids}`, {
      headers: getPimsHttpHeaders(),
    });
    if (!response.ok) {
      throw new Error(RestApiErrorMessage.GENERIC);
    }
    const json = await response.json();
    return json;
  }

  static async getMultipleSpecLimits(stepIds: Array<number>): Promise<MultiSpecLimitData> {
    const stepIdList = stepIds.join(',');
    const url = `${ConfigService.process}/api/v1/spec_limits?step_ids=${stepIdList}&status=approved`;
    const response = await fetch(url, {
      headers: getPimsHttpHeaders(),
    });
    if (!response.ok) {
      throw new Error(RestApiErrorMessage.GENERIC);
    }
    const json = await response.json();
    return json;
  }

  static async sendElnRequest(body: object): Promise<SpreadsheetResponse<any>> {
    const headers = getElnHttpHeaders();
    const urlParams = new URLSearchParams(window.location.search);
    const versionId = urlParams.get('versionId');
    const url = `${ConfigService.eln}/ewb/services/1.0/spreadsheet/data?versionId=${versionId}`;

    const response = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      throw new Error(RestApiErrorMessage.GENERIC);
    }
    const json = await response?.json();
    return json;
  }

  static async getCatalogTuples(tupleGuids: Array<string>): Promise<CatalogTermData> {
    const headers = getElnHttpHeaders();
    const url = `${ConfigService.eln}/ewb/services/1.0/catalog/batch/tuples`;
    const body = {
      id: tupleGuids,
    };

    const response = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      throw new Error(RestApiErrorMessage.GENERIC);
    }

    const json = (await response?.json()) as CatalogTermData;
    return json;
  }

  static async getCatalogElements(tupleGuids: Array<string>): Promise<CatalogPropertyData> {
    const headers = getElnHttpHeaders();
    const url = `${ConfigService.eln}/ewb/services/1.0/catalog/batch/elements`;
    const body = {
      id: tupleGuids,
    };

    const response = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      throw new Error(RestApiErrorMessage.GENERIC);
    }

    const json = (await response?.json()) as CatalogPropertyData;
    return json;
  }

  static importParameterInformation(batchOptions: BatchOptions) {
    const body = SpreadsheetUtils.generateBatchUpdateRequest(batchOptions);
    return this.sendElnRequest(body);
  }

  static clearParameterInformation() {
    const body = SpreadsheetUtils.generateBatchClearRequest();
    return this.sendElnRequest(body);
  }

  static getCells(queries: SpreadsheetDataQueries): Promise<SpreadsheetResponse<TableDataResponse>> {
    const body = SpreadsheetUtils.formCellTableDataRequest(queries);
    return this.sendElnRequest(body);
  }

  static getTableColumns(): Promise<SpreadsheetResponse<StructureResponse>> {
    const body = SpreadsheetUtils.formTableColumnsRequest();
    return this.sendElnRequest(body);
  }
}
