// Copyright ID Business Solutions Ltd. 2023

import { useIntlMessage } from '@idbs/idbs-react-hooks';
import React from 'react';
import { AckLink, BuildNumber, EmptyRight, FooterContainer } from './Footer.style';

const Footer = () => {
  const intlMessage = useIntlMessage();

  const buildNumber = import.meta.env.VITE_BUILD_NUMBER;
  const buildText = `Build ${buildNumber}`;

  const ack_url = `https://idbsacknowledgements.s3.amazonaws.com/Process_Locator_ACK-${buildNumber}.html`;

  return (
    <FooterContainer>
      <BuildNumber data-testid='build-number'>{buildText}</BuildNumber>
      <AckLink href={ack_url} target='_blank' data-testid='acknowledgements-link'>
        {intlMessage('acknowledgements')}
      </AckLink>
      <EmptyRight>
        {
          // this is to keep things centred
        }
        {buildText}
      </EmptyRight>
    </FooterContainer>
  );
};

export default Footer;
