// Copyright ID Business Solutions Ltd. 2023

import { Dots } from '@idbs/idbs-react-components';
import { useIntlMessage } from '@idbs/idbs-react-hooks';
import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { RestApi } from '../../services/RestApi';
import { WarningDialog } from '../WarningDialog/WarningDialog';
import { LeftContent } from './StepSelect.style';
import { StepTree } from './StepTree/StepTree';

import { SortableNamedItem } from '../../types/shared';
import { GenericError } from '../GenericError/GenericError';

type StepSelectProps = { selectedProductId: number };

const StepSelect = ({ selectedProductId }: StepSelectProps) => {
  const [isTreeError, setTreeError] = useState<boolean>(false);
  const intlMessage = useIntlMessage();

  const {
    isLoading,
    data: units,
    refetch,
  } = useQuery({
    queryKey: ['steps', selectedProductId],
    queryFn: () => RestApi.getSteps(selectedProductId),
  });

  const onRetry = () => {
    if (isTreeError) {
      setTreeError(false);
    } else {
      refetch({ throwOnError: true });
    }
  };
  const handleTreeError = () => {
    setTreeError(true);
  };

  if (isLoading) {
    return <Dots />;
  } else if (isTreeError || !units) {
    const header = intlMessage('app.no.step.header');
    return <WarningDialog onRetry={onRetry} header={header} />;
  }

  units.sort((a: SortableNamedItem, b: SortableNamedItem) => a.position - b.position);
  return (
    <LeftContent data-testid='step-select'>
      {units?.length === 0 ? (
        <GenericError
          iconColour='yellow'
          message={intlMessage('app.no.step.processes')}
          explainer={intlMessage('app.no.select.another')}
        />
      ) : (
        <StepTree treeData={units} onError={handleTreeError} />
      )}
    </LeftContent>
  );
};

export { StepSelect };
