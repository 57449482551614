// Copyright ID Business Solutions Ltd. 2023

import styled from 'styled-components';

export const TreeContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

export const DisabledXbox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  transition: all 150ms;
  box-sizing: border-box;
  margin-right: 7px;
  background: white;
  border: solid 1px;
  background: var(--color-neutral-50);
  border: solid 1px var(--color-neutral-200);
  svg {
    color: var(--color-neutral-200);
  }
}


`;
