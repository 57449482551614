// Copyright ID Business Solutions Ltd. 2023

import { LoadingIndicator } from '@idbs/idbs-react-components';
import { useIntlMessage } from '@idbs/idbs-react-hooks';
import React from 'react';

import { ImportButton } from './ImportButton/ImportButton';
import { ImportSelectedWrapper } from './ImportData.style';

import { ActionType, useApplicationState } from '../../state/state';
import { ImportMutation, SelectedStep } from '../../types/shared';
import { UnselectButton } from './UnselectButton/UnselectButton';

type ImportDataProps = {
  mutation: ImportMutation;
};

export const actionImportData = (mutation: ImportMutation, steps?: SelectedStep[] | undefined) => {
  if (steps) {
    mutation.mutate({ steps });
  }
};

const ImportData = ({ mutation }: ImportDataProps) => {
  const {
    state: { selectedSteps },
  } = useApplicationState();

  const intlMessage = useIntlMessage();
  const {
    dispatch,
    state: { importError },
  } = useApplicationState();

  const importData = () => {
    actionImportData(mutation, selectedSteps);
  };

  const unselectImport = () => {
    dispatch({
      type: ActionType.SELECT_STEPS,
      steps: [],
    });
  };

  const stepCount = selectedSteps ? selectedSteps.length : 1;
  return (
    <>
      <ImportSelectedWrapper data-testid='import-data-message'>
        {intlMessage('app.import.message', { stepCount })}
      </ImportSelectedWrapper>
      <UnselectButton onClick={unselectImport} disabled={!!importError} />
      <ImportButton onClick={importData} disabled={!!importError} />
      <LoadingIndicator visible={mutation.isLoading} text={intlMessage('importing.data')} />
    </>
  );
};

export { ImportData };
