// Copyright ID Business Solutions Ltd. 2024

import { RestApi } from '../../services/RestApi';
import { ProcessStepData } from '../ParameterUtils';

export type CatalogTerm = {
  id: number;
  name: string;
  term_guid: string;
  term_name: string;
  term_path: string;
  term_key_property: string;
};

export interface CatalogTuple {
  id: number;
  term_guid: string;
  term_id: number;
  tuple_guid: string;
  tuple_key_property_value: string; // or possibly number?
}

export interface ExtendedCatalogTuple extends CatalogTuple {
  label_name?: string;
  term_name?: string;
  term_path?: string;
  term_key_property?: string;
  type?: string;
}

export interface TupleContent {
  name: string;
  value: any;
  displayValue: any;
}

type GUID = string;

type CatalogResponseTuple = {
  data: Array<TupleContent>;
  id: GUID; // tuple guid
  enabled: boolean;
  defaultTuple: boolean;
  deletable: boolean;
  editable: boolean;
  termGuid?: string; // so we can map this
};

export type CatalogResponseTuples = Array<CatalogResponseTuple>;

type CatalogTuples = {
  path: GUID; // term guid
  tuple: CatalogResponseTuples;
};

export type CatalogEntry = {
  dictionary?: any;
  term?: any;
  tuples: CatalogTuples;
};

type CatalogElementProperty = {
  name: string;
  description: any;
  format: any;
  constraint: string;
  unit: any;
  overrides: any;
  allOverrides: any;
  termReference: {
    path: string;
    id: string;
  };
  id: string;
  type: 'STRING';
  key: boolean;
  displayed: boolean;
  linkTermPath: any;
  linkTermId: any;
  unitTermPath: any;
  unitTermId: any;
  hidden: boolean;
  owner: boolean;
  abstractTermRef: boolean;
};

type CatalogElementTerm = {
  path: string;
  description: any;
  hierarchy: any;
  fullHierarchy: any;
  properties: {
    property: Array<CatalogElementProperty>;
  };
  tuples: any;
  id: string;
  enabled: boolean;
  deletable: boolean;
  editable: boolean;
  system: boolean;
  abstractTerm: boolean;
};

export type CatalogProperties = {
  dictionary?: any;
  term: CatalogElementTerm;
};

export type CatalogTermData = {
  catalog: Array<CatalogEntry>;
};

export type CatalogPropertyData = {
  catalog: Array<CatalogProperties>;
};

type ElmentData = {
  [key: string]: // term guid
  {
    [key: string]: // tuple guid
    {
      key: boolean;
      type: string;
    };
  };
};

export interface ExtendedTupleContent extends TupleContent {
  type: string;
}

export type TupleData = {
  [key: string]: Array<ExtendedTupleContent>;
};

export const emptyExtendedCatalogTuple: ExtendedCatalogTuple = {
  id: 0,
  term_guid: '',
  term_id: 0,
  term_path: '',
  term_name: '',
  label_name: '',
  tuple_guid: '',
  tuple_key_property_value: '',
  type: '',
  term_key_property: '',
};

export const emptyExtendedTupleContent: ExtendedTupleContent = {
  name: '',
  value: '',
  displayValue: '',
  type: '',
};

export const getCatalogData = async (processDataArray: Array<ProcessStepData>) => {
  const uniqueTupleGuids = new Set<string>();
  const uniqueTermGuids = new Set<string>();
  if (processDataArray && processDataArray.length > 0) {
    extractGuidsFromProcessSteps(uniqueTupleGuids, uniqueTermGuids, processDataArray);
  }
  const tupleGuids = Array.from(uniqueTupleGuids) ?? [];
  const termGuids = Array.from(uniqueTermGuids) ?? [];
  const [catalogTermData, catalogPropertyData] = await Promise.all([
    RestApi.getCatalogTuples(termGuids),
    RestApi.getCatalogElements(termGuids),
  ]);
  return { tupleGuids, catalogPropertyData, catalogTermData };
};

const extractGuidsFromProcessSteps = (
  uniqueTupleGuids: Set<string>,
  uniqueTermGuids: Set<string>,
  processSteps: Array<ProcessStepData>
) => {
  processSteps?.forEach((processStep) => {
    processStep.parameters?.forEach((parameter) => {
      parameter.catalog_tuples?.forEach((catalogTuple) => {
        uniqueTermGuids.add(catalogTuple.term_guid);
        uniqueTupleGuids.add(catalogTuple.tuple_guid);
      });
    });
    extractGuidsFromProcessSteps(uniqueTupleGuids, uniqueTermGuids, processStep.process_steps);
  });
};

export const processCatalogData = (catalogPropertyData: CatalogPropertyData, catalogTermData: CatalogTermData) => {
  const elementData: ElmentData = {};
  catalogPropertyData.catalog.forEach((element) => {
    const termGuid = element.term.id;
    elementData[termGuid] = {};
    element.term.properties.property.forEach((property) => {
      elementData[termGuid][property.name] = { key: !!property.key, type: property.type };
    });
  });

  const termPropertiesLookup: TupleData = {};
  const tupleProperties: TupleData = {};
  catalogTermData.catalog.forEach((term) => {
    const termGuid = term.tuples.path;
    term.tuples.tuple
      .filter((tuple) => tuple.enabled)
      .forEach((tuple) => {
        const properties = tuple.data.map((property) => {
          return {
            ...property,
            type: elementData[termGuid][property.name].type,
          };
        });
        const tupleGuid = tuple.id;
        termPropertiesLookup[termGuid] = properties;
        tupleProperties[tupleGuid] = properties;
      });
  });
  return { termPropertiesLookup, tupleProperties };
};
