// Copyright ID Business Solutions Ltd. 2024

import { NamedItem, SelectedStep } from '../types/shared';
import { CatalogTerm, ExtendedCatalogTuple, TupleData } from './CatalogUtils/CatalogUtilities';

export type ParameterData = {
  query_alias?: string;
  id?: number;
  site?: string;
  siteId?: number;
  product?: string;
  productId?: number;
  catalog_terms?: Array<CatalogTerm>;
  catalog_tuples?: Array<ExtendedCatalogTuple>;
};

export interface ProcessStepData extends NamedItem {
  parent: string;
  parent_id?: number;
  parameters: Array<ParameterData>;
  process_steps: Array<ProcessStepData>;
  catalog_terms?: Array<CatalogTerm>;
}

type ExtraParameterData = {
  parameterDataArray: Array<ParameterData>;
  selectedSteps: Array<SelectedStep>;
  levels: ParameterData;
  stepData: ProcessStepData;
  termPropertiesLookup: TupleData;
};

const missingStep: SelectedStep = {
  site_name: 'Site name missing',
  site_id: -1,
  product_name: 'Product name missing',
  product_id: -1,
  name: 'Missing name',
  id: -1,
};

const missingCatalogTerm: CatalogTerm = {
  id: -1,
  name: 'missing name',
  term_guid: 'missing term guid',
  term_name: 'missing term name',
  term_path: 'missing term path',
  term_key_property: 'missing term key property',
};

export const processDataHasNoParameters = (processData: ProcessStepData) =>
  processData.parameters.length === 0 &&
  (processData.process_steps.length === 0 ||
    processData.process_steps.every((subSteps) => processDataHasNoParameters(subSteps)));

/**
 * Take the getParameters step data object and convert to array of parameter data with step hierarchy
 * and site and product added along with type to the catalog tuples
 */
export const processStepDataToParameterData = (
  processDataArray: Array<ProcessStepData>,
  selectedSteps: Array<SelectedStep>,
  termPropertiesLookup: TupleData
): Array<ParameterData> => {
  const levels: ParameterData = {};
  const parameterData: Array<ParameterData> = [];
  processDataArray.forEach((processData) =>
    subProcessStepDataToParameterData({
      parameterDataArray: parameterData,
      selectedSteps,
      levels,
      stepData: processData,
      termPropertiesLookup,
    })
  );
  return parameterData;
};

/**
 * Add parameter data from this process step and by recursion each process step below
 * to the array of parameter data
 */
const subProcessStepDataToParameterData = (extraParameterData: ExtraParameterData): Array<ParameterData> => {
  const { parameterDataArray, selectedSteps, levels, stepData, termPropertiesLookup } = extraParameterData;
  if (stepData.level === 1) {
    const selectedStep = selectedSteps.find((step) => step.id === stepData.id) ?? missingStep;
    levels.site = selectedStep.site_name;
    levels.siteId = selectedStep.site_id;
    levels.product = selectedStep.product_name;
    levels.productId = selectedStep.product_id;
  }
  if (stepData.catalog_terms) {
    levels.catalog_terms = stepData.catalog_terms;
  }
  const levelName = `level${stepData.level}Name`;
  const levelId = `level${stepData.level}Id`;
  levels[levelName] = stepData.name;
  levels[levelId] = stepData.id;
  stepData.parameters.forEach((parameterData) =>
    parameterDataArray.push(generateExpandedParameterData(parameterData, levels, termPropertiesLookup))
  );
  if (stepData.process_steps) {
    stepData.process_steps.forEach((steps) =>
      subProcessStepDataToParameterData({ ...extraParameterData, stepData: steps })
    );
  }
  levels[levelName] = '';
  levels[levelId] = '';
  return parameterDataArray;
};

/**
 * Expand the parameter data to include the term details to the catalog tuples
 */
const generateExpandedParameterData = (
  parameterData: ParameterData,
  levels: ParameterData,
  termPropertiesLookup: TupleData
): ParameterData => {
  const expandedCatalogData = parameterData.catalog_tuples?.map((tuple) => {
    const catalogTerm = levels.catalog_terms?.find((term) => term.term_guid === tuple.term_guid) ?? missingCatalogTerm;
    const tupleProperty = termPropertiesLookup[tuple.term_guid]?.find(
      (item) => item.name === catalogTerm.term_key_property
    );
    return {
      ...tuple,
      label_name: catalogTerm.name,
      term_name: catalogTerm.term_name,
      term_path: catalogTerm.term_path,
      term_key_property: catalogTerm.term_key_property,
      type: tupleProperty?.type,
    };
  });
  return { ...parameterData, ...levels, catalog_tuples: expandedCatalogData };
};
